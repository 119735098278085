import axios from 'axios';
import { put, select, call } from 'redux-saga/effects';

// CONSTANTS
import * as endpoints from '@/constants/api';
import { WN_S_ACTION_LIKE, WN_S_ACTION_ADD } from '@/constants/watchNext';

// UTILITY
import store from '@/utility/store';
import { getLink } from '@/utility/routes';
import {
  analyticsIdentify,
  analyticsStartedWNForm,
  analyticsVoted,
  analyticsRecommendedShow,
} from '@/utility/analytics';
import { showErrorNotification, getPartnerSlug } from '@/utility/saga';

// STORE
import { WatchNextActions, UserActions, NotifActions } from '@/store/actions';
import { AuthSelectors, WatchNextSelectors } from '@/store/selectors';

// LOCALIZATION
import { t } from '@/locale/i18n';
import { WATCH_NEXT_DELETE_LIST } from '@/constants/api';
import { userGetContentCountSaga } from '@/store/saga/user';

const {
  startWatchNextFrom,
  submitWatchNextForm,
  getWatchNextResults,
  getMoreWatchNextShows,
  submitWatchNextVote,
  addEmailToWNResults,
  suggestionsAutocompleteWN,
  addWatchNextSuggestion,
  addToWatchNextVotelist,
  removeFromWatchNextVotelist,
  saveWatchNextVotelist,
  deleteWNVotelist,
} = WatchNextActions;
const { userGetContentCount } = UserActions;
const { pushSuccessNotificationAction } = NotifActions;

export function* getWNResultsSaga(action) {
  yield put(getWatchNextResults.start());

  const { wn_id, token, onError } = action.payload;
  const url = getLink(endpoints.WATCH_NEXT_GET_RESULTS, { wn_id });

  try {
    const { data: respData } = yield axios.get(url, {
      params: { token },
      cache: { ignoreCache: true },
    });

    yield put(
      getWatchNextResults.success({
        data: respData.data,
        recommended_content: respData.data.recommended_content,
      }),
    );
  } catch (error) {
    const errStatus = error?.response?.status;

    if (errStatus !== 422) {
      yield call(showErrorNotification, error);
    }

    if (onError) onError(errStatus);

    yield put(getWatchNextResults.fail());
  }
}

export function* startWNFromSaga(action) {
  yield put(startWatchNextFrom.start());

  const { token, wn_id, edit = false } = action.payload;
  const isEdit = !!token && !!wn_id && edit;
  const partner = yield call(getPartnerSlug);
  let curWnId = wn_id;
  let curToken = token;

  try {
    const { data: respData } = yield axios.get(endpoints.WATCH_NEXT_GET_DICTIONARY, {
      params: { partner },
    });

    if (!isEdit) {
      const { data: initRespData } = yield axios.post(endpoints.WATCH_NEXT_INIT_FORM, {
        token,
        partner,
      });

      curWnId = initRespData.unique_id;
      curToken = initRespData.user_token;

      store.set('wn_id', curWnId);
      store.set('wn_token', curToken);

      analyticsStartedWNForm({ id: curWnId });
    } else {
      yield call(getWNResultsSaga, { payload: { wn_id, token } });
    }

    yield put(
      startWatchNextFrom.success({
        dictionary: respData.data,
        unique_id: curWnId,
        user_token: curToken,
      }),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(startWatchNextFrom.fail());
  }
}

export function* submitWNFormSaga(action) {
  yield put(submitWatchNextForm.start());

  const { wn_id, data = {}, callback } = action.payload;
  const url = getLink(endpoints.WATCH_NEXT_SUBMIT_FORM, { wn_id });
  const isAuth = yield select(AuthSelectors.getIsAuth);

  try {
    const { data: respData } = yield axios.post(url, data);

    if (isAuth) {
      yield put(userGetContentCount.init());
    }

    yield put(submitWatchNextForm.success());

    if (callback) callback({ wn_id: respData.unique_id });
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(submitWatchNextForm.fail());
  }
}

export function* getMoreWNShowsSaga(action) {
  yield put(getMoreWatchNextShows.start());

  const { wn_id, token, page } = action.payload;
  const url = getLink(endpoints.WATCH_NEXT_GET_RESULTS, { wn_id });

  try {
    const { data: respData } = yield axios.get(url, { params: { token, page } });

    yield put(
      getMoreWatchNextShows.success({
        page: respData.data.page,
        recommended_content: respData.data.recommended_content,
      }),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getMoreWatchNextShows.fail());
  }
}

export function* submitWNVoteSaga(action) {
  const { wn_id, content_id, data = {} } = action.payload;

  yield put(submitWatchNextVote.start({ content_id }));

  const token = yield select(WatchNextSelectors.getWNToken);
  const url = getLink(endpoints.WATCH_NEXT_SUBMIT_VOTE, { wn_id, content_id });

  try {
    const { data: respData } = yield axios.post(url, data, { params: { token } });

    if (!data.token) {
      store.set('wn_token', respData.user_token);
    }

    yield put(submitWatchNextVote.success(respData));

    analyticsVoted({ id: wn_id, showId: content_id, vote_type: data.vote_type });
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(submitWatchNextVote.fail({ content_id }));
  }
}

export function* addEmailToWNResultsSaga(action) {
  yield put(addEmailToWNResults.start());

  const { wn_id, token, email } = action.payload;
  const url = getLink(endpoints.WATCH_NEXT_ADD_EMAIL, { wn_id });
  const partner = yield call(getPartnerSlug);

  try {
    yield axios.post(url, { token, email, partner });

    store.set('user-email', email);

    yield put(addEmailToWNResults.success({ email }));

    analyticsIdentify(email, { email });

    yield put(pushSuccessNotificationAction(t('notification.successfullyAddedEmail')));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(addEmailToWNResults.fail());
  }
}

export function* suggestionsAutocompleteWNSaga(action) {
  yield put(suggestionsAutocompleteWN.start());

  const { wn_id, params = {} } = action.payload;
  const url = getLink(endpoints.WATCH_NEXT_SEARCH, { wn_id });

  try {
    const { data: respData } = yield axios.get(url, { params });

    yield put(suggestionsAutocompleteWN.success({ data: respData.data }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(suggestionsAutocompleteWN.fail());
  }
}

export function* addWNSuggestionSaga(action) {
  const { wn_id, data, callback } = action.payload;
  const content_ids = data.content_ids;

  yield put(addWatchNextSuggestion.start({ content_ids }));

  const url = getLink(endpoints.WATCH_NEXT_ADD_SUGGESTION, { wn_id });

  try {
    const { data: respData } = yield axios.post(url, data);
    let message = '';

    if (!data.token) {
      store.set('wn_token', respData.user_token);
    }

    if (respData.action === WN_S_ACTION_ADD) {
      message = t('notification.showAdded');
    } else if (respData.action === WN_S_ACTION_LIKE) {
      message = t('notification.alreadyRecommended');
    }

    if (message) {
      yield put(pushSuccessNotificationAction(message));
    }

    analyticsRecommendedShow({
      id: wn_id,
      show_name: data.show_name,
    });

    yield put(addWatchNextSuggestion.success({ ...respData, content_ids }));

    if (callback?.call) callback();
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(addWatchNextSuggestion.fail({ content_ids }));
  }
}

export function* addToWNVotelistSaga(action) {
  const { wn_id, content_id, new_content, token, callback } = action.payload;

  yield put(addToWatchNextVotelist.start({ content_id }));

  const isAuth = yield select(AuthSelectors.getIsAuth);
  const url = getLink(endpoints.WATCH_NEXT_CONTENT, { wn_id, content_id });

  try {
    yield axios.post(url, null, { params: { token } });

    yield put(addToWatchNextVotelist.success({ content_id, new_content }));

    if (isAuth) {
      yield put(userGetContentCount.init());
    }

    if (callback?.call) callback();
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(addToWatchNextVotelist.fail({ content_id }));
  }
}

export function* removeFromWNVotelistSaga(action) {
  const { wn_id, content_id, token, callback } = action.payload;

  yield put(removeFromWatchNextVotelist.start({ content_id }));

  const isAuth = yield select(AuthSelectors.getIsAuth);
  const url = getLink(endpoints.WATCH_NEXT_CONTENT, { wn_id, content_id });

  try {
    yield axios.delete(url, { params: { token } });

    yield put(removeFromWatchNextVotelist.success({ content_id }));

    if (isAuth) {
      yield put(userGetContentCount.init());
    }

    if (callback?.call) callback();
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(removeFromWatchNextVotelist.fail({ content_id }));
  }
}

export function* saveWNVotelistSaga(action) {
  const { wn_id, data } = action.payload;

  yield put(saveWatchNextVotelist.start());

  const url = getLink(endpoints.WATCH_NEXT_COMPLETE, { wn_id });

  try {
    if (data.title) {
      yield call(submitWNFormSaga, { payload: { wn_id, data } });
    }

    yield axios.post(url, { token: data.token });

    yield put(saveWatchNextVotelist.success({ title: data.title }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(removeFromWatchNextVotelist.fail());
  }
}

export function* deleteWNVotelistSaga(action) {
  const { wn_id, onSuccess } = action.payload;

  const url = getLink(WATCH_NEXT_DELETE_LIST, { wn_id });
  const isAuth = yield select(AuthSelectors.getIsAuth);

  try {
    yield put(deleteWNVotelist.start());

    yield axios.delete(url);

    if (isAuth) {
      yield call(userGetContentCountSaga);
    }

    yield put(deleteWNVotelist.success({ wn_id }));

    if (onSuccess) onSuccess();
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(deleteWNVotelist.fail());
  }
}
