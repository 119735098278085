import axios from 'axios';
import { put, call, select, all, fork } from 'redux-saga/effects';
import queryString from 'query-string';

// CONSTANTS
import {
  APP_DETAILS,
  APP_CHANNELS,
  APP_GET_REVIEWS,
  APP_ADD_REVIEW,
  APP_GET_EXTERNAL_LINK,
  APP_LIST_CHANGE_STATUS,
  APP_LIST_REMOVE,
} from '@/constants/api';
import { appsListStatuses } from '@/constants/app';

// UTILITY
import bugsnagClient from '@/utility/bugsnagClient';
import store from '@/utility/store';
import { updateUrl } from '@/utility/common';
import { getLink, getSerializedParams } from '@/utility/routes';
import { getExternalUrlParams, externalLink } from '@/utility/analytics';
import { getPartnerSlug, showErrorNotification } from '@/utility/saga';

// STORE
import { AppDetailsActions, UserActions, NotifActions } from '../actions';
import { AppSelectors, PartnerSelectors, AuthSelectors } from '../selectors';
import { swimlanesGetSaga } from './swimlanes';
import { getWPRelatedPostsSaga } from './wpBlog';

// LOCALIZATION
import { t } from '@/locale/i18n';

const { userGetContentCount } = UserActions;
const {
  getAppDetails,
  getAppChannels,
  getAppReviews,
  addAppReview,
  getExternalLink,
  appListUpdateStatus,
  appListRemoveStatus,
} = AppDetailsActions;
const { pushSuccessNotificationAction, pushErrorNotificationAction } = NotifActions;

export function* getAppSwimlanesSaga(action) {
  const { params = {} } = action.payload;

  yield all([
    call(swimlanesGetSaga, {
      payload: {
        params: { page: 'app', section: 1, ...params },
      },
    }),
  ]);
}

export function* getAppDetailsSaga(action) {
  yield put(getAppDetails.start());

  const { slug, onError } = action.payload;
  const partner = yield call(getPartnerSlug);
  const ytpc = yield select(AppSelectors.getYtpc);
  const serializedParams = getSerializedParams({
    ytpc,
  });

  let url = getLink(APP_DETAILS, { slug });

  if (partner) {
    url += `/${partner}`;
  }

  try {
    const { data: respData } = yield axios.get(url, serializedParams);

    yield fork(getAppSwimlanesSaga, {
      payload: {
        params: { app_id: respData.app.id },
      },
    });

    if (respData.posts.length) {
      const postIDs = respData.posts.reduce((acc, post) => acc.concat(post.wp_post_id), []);

      yield fork(getWPRelatedPostsSaga, {
        payload: { params: { per_page: 4, include: postIDs } },
      });
    }

    yield put(getAppDetails.success({ data: respData }));
  } catch (error) {
    const status = error.response?.status;

    if ([322, 422, 404].includes(status)) {
      bugsnagClient.notify(error, event => {
        event.severity = 'info';
        event.errors[0].errorMessage += `. App slug: ${slug}`;
      });
    } else {
      yield call(showErrorNotification, error);
    }

    if (onError) onError(status);

    yield put(getAppDetails.fail());
  }
}

export function* getAppChannelsSaga(action) {
  yield put(getAppChannels.start());

  const { slug } = action.payload;
  const url = getLink(APP_CHANNELS, { slug });
  const partner = yield call(getPartnerSlug);
  const dma_id = yield store.get('dma_id');
  const serializedParams = getSerializedParams({
    partner,
    dma_id,
  });

  try {
    const { data: respData } = yield axios.get(url, serializedParams);

    yield put(getAppChannels.success({ data: respData }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getAppDetails.fail());
  }
}

export function* getAppReviewsSaga(action) {
  yield put(getAppReviews.start());

  const { slug, pagination } = action.payload;
  const partner = yield call(getPartnerSlug);

  let url = getLink(APP_GET_REVIEWS, { slug });

  if (partner) {
    url += `/${partner}`;
  }

  try {
    const { data: respData } = yield axios.get(url, { params: pagination });

    yield put(getAppReviews.success({ data: respData.data, meta: respData.meta }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getAppReviews.fail());
  }
}

export function* addAppReviewSaga(action) {
  yield put(addAppReview.start());

  const { app_id, data } = action.payload;
  const url = getLink(APP_ADD_REVIEW, { app_id });
  const app_ids = store.get('app_ids') || [];

  app_ids.push(app_id);
  store.set('app_ids', app_ids);

  try {
    const { data: respData } = yield axios.post(url, data);

    yield call(getAppReviewsSaga, { payload: { slug: app_id, page: 1, per_page: 3 } });

    yield put(pushSuccessNotificationAction(t('notification.successfullySentReview')));

    yield put(
      addAppReview.success({
        rating: respData.rating,
        appRatings: respData.appRatings,
        ratingCount: respData.rating_сount,
      }),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(addAppReview.fail());
  }
}

export function* getAppExtraLinkSaga(action) {
  yield put(getExternalLink.start());

  const { slug, partner = '', onError } = action.payload;
  let url = getLink(APP_GET_EXTERNAL_LINK, { slug });

  if (partner) {
    url += `/${partner}`;
  }

  try {
    const { data: respData } = yield axios.get(url);

    if (respData.url) {
      const parsedParams = queryString.parse(document.location.search);
      const hasTrackParam = Object.prototype.hasOwnProperty.call(parsedParams, 'track');
      const page = parsedParams.page || '';
      const source = parsedParams.source || '';

      let uniqueId = parsedParams.unique_id;

      if (!uniqueId) {
        uniqueId = yield select(AuthSelectors.getAnonymousId);
      }

      const externalUrlParams = yield call(getExternalUrlParams, {
        unique_id: uniqueId,
        page,
        source,
      });
      const updatedUrl = yield call(updateUrl, respData.url, externalUrlParams);

      /* eslint-disable */
      console.log('updatedUrl', updatedUrl);
      /* eslint-enable */

      if (hasTrackParam) {
        externalLink({
          app_id: respData.id,
          href: updatedUrl,
          is_affiliate: !!respData.is_affiliate,
          location: parsedParams.location || '',
          partner: partner || '',
          partner_id: parsedParams.partner_id || '',
          uniqueId: parsedParams.unique_id || '',
        });
      }

      window.location.href = updatedUrl;
    } else {
      if (onError) yield call(onError);

      yield put(pushErrorNotificationAction(`The application was not found with "${slug}" name!`));
    }

    yield put(getExternalLink.success());
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getExternalLink.fail());
  }
}

export function* appListUpdateStatusSaga(action) {
  const { data, callback } = action.payload;

  yield put(appListUpdateStatus.start({ app_id: data.apps[0].app_id }));

  try {
    yield axios.post(APP_LIST_CHANGE_STATUS, data);

    yield put(userGetContentCount.init());

    const partner = yield select(PartnerSelectors.getPartnerData);
    const partnerOverwrittenNotif =
      appsListStatuses.have === data.apps[0].status
        ? partner?.text_overwrites?.app_add_to_have
        : partner?.text_overwrites?.app_add_to_want;

    yield put(
      pushSuccessNotificationAction(
        partnerOverwrittenNotif || t('notification.successfullyAddedToList'),
      ),
    );

    if (callback) callback();

    yield put(appListUpdateStatus.success({ app_id: data.apps[0].app_id }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(appListUpdateStatus.fail({ app_id: data.apps[0].app_id }));
  }
}

export function* appListRemoveStatusSaga(action) {
  const { app_id, callback } = action.payload;

  yield put(appListRemoveStatus.start({ app_id }));
  const url = getLink(APP_LIST_REMOVE, { app_id });

  try {
    yield axios.delete(url);

    yield put(userGetContentCount.init());

    const partner = yield select(PartnerSelectors.getPartnerData);

    yield put(
      pushSuccessNotificationAction(
        partner?.text_overwrites?.app_remove_from_list ||
          t('notification.successfullyRemovedFromList'),
      ),
    );

    if (callback) callback();

    yield put(appListRemoveStatus.success({ app_id }));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(appListRemoveStatus.fail({ app_id }));
  }
}
