export default {
  title: 'Watchlist',
  pageDescription: "Welcome to your friend's persoanlized watchlist. Enjoy!",
  filters: {
    selected: {
      title: 'Showing content available on these apps:',
    },
  },
  emptyState: {
    default: {
      title: 'This watchlist is empty',
      subtitle: 'This watchlist is empty. Try to find what to watch on TV shows & Movies page',
    },
    myApps: {
      title:
        "Oops, looks like you don't have any titles in this Watchlist from your apps. No problem!",
      subtitle:
        'You can easily add titles to your watchlist from your apps in the TV Shows and Movies area or add more apps to your profile through the Marketplace.',
    },
  },
};
