import { format } from 'date-fns';

// UTILITY
import bugsnag from '@/utility/bugsnagClient';

export function convertToDate(date) {
  return new Date(date.replace(/-/g, '/'));
}

export function formatDate(date, formatStr) {
  let updDate = '';

  if (date) {
    try {
      updDate = format(convertToDate(date), formatStr);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      bugsnag.notify(new Error('FormatDate error', { cause: e }));
    }
  }

  return updDate;
}

export function getUtcDate() {
  const now = new Date();

  return `${now.toISOString().split('.')[0]}Z`;
}

export default getUtcDate;
