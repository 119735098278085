import produce from 'immer';

import {
  getInsights,
  getWPPosts,
  clearWPPosts,
  getWPPost,
  getWPFeaturedPost,
  getWPRelatedPosts,
  cleanWPRelatedPosts,
  getWPCategories,
  getWPAuthor,
  getWPCategory,
  getWPPage,
  getNews,
} from '../actions/wpBlog';

const initialState = {
  featuredPost: {},
  list: {
    posts: [],
    meta: {
      page: 1,
      totalpages: 1,
    },
  },
  categories: [],
  postData: {},
  author: {},
  category: {},
  relatedPosts: [],
  pageData: {},
  loadings: {
    main: false,
    posts: false,
    featuredPost: false,
    relatedPosts: false,
    categories: false,
    author: false,
    category: false,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET INSIGHTS
    case getInsights.types.START:
      state.loadings.main = true;
      return state;

    case getInsights.types.SUCCESS:
    case getInsights.types.FAIL:
      state.loadings.main = false;
      return state;

    // GET POSTS
    case getWPPosts.types.START:
      state.loadings.posts = true;
      return state;

    case getWPPosts.types.SUCCESS:
      state.list.posts = action.payload.data;
      state.list.meta.page = action.payload.page;
      state.list.meta.totalpages = action.payload.totalpages;
      state.loadings.posts = false;
      return state;

    case getWPPosts.types.FAIL:
      state.loadings.posts = false;
      return state;

    // CLEAR WP POSTS
    case clearWPPosts.types.INIT:
      state.list.posts = initialState.list.posts;
      state.list.meta = initialState.list.meta;
      state.categories = initialState.categories;
      return state;

    // GET POST
    case getWPPost.types.START:
      state.postData = initialState.postData;
      state.loadings.main = true;
      return state;

    case getWPPost.types.SUCCESS:
      state.postData = action.payload.data;
      state.relatedPosts = [];
      state.loadings.main = false;
      return state;

    case getWPPost.types.FAIL:
      state.loadings.main = false;
      return state;

    // GET FEATURED POST
    case getWPFeaturedPost.types.START:
      state.featuredPost = {};
      state.loadings.featuredPost = true;
      return state;

    case getWPFeaturedPost.types.SUCCESS:
      state.featuredPost = action.payload.data;
      state.loadings.featuredPost = false;
      return state;

    case getWPFeaturedPost.types.FAIL:
      state.loadings.featuredPost = false;
      return state;

    // GET RELATED POSTS
    case getWPRelatedPosts.types.START:
      state.relatedPosts = [];
      state.loadings.relatedPosts = true;
      return state;

    case getWPRelatedPosts.types.SUCCESS:
      state.relatedPosts = action.payload.data;
      state.loadings.relatedPosts = false;
      return state;

    case getWPRelatedPosts.types.FAIL:
      state.loadings.relatedPosts = false;
      return state;

    // CLEAN RELATED POSTS
    case cleanWPRelatedPosts.types.INIT:
      state.relatedPosts = initialState.relatedPosts;
      return state;

    // GET CATEGORIES
    case getWPCategories.types.START:
      state.loadings.categories = true;
      return state;

    case getWPCategories.types.SUCCESS:
      state.categories = action.payload.data;
      state.loadings.categories = false;
      return state;

    case getWPCategories.types.FAIL:
      state.loadings.categories = false;
      return state;

    // GET AUTHOR
    case getWPAuthor.types.START:
      state.loadings.author = true;
      return state;

    case getWPAuthor.types.SUCCESS:
      state.author = action.payload.data;
      state.loadings.author = false;
      return state;

    case getWPAuthor.types.FAIL:
      state.loadings.author = false;
      return state;

    // GET CATEGORY
    case getWPCategory.types.START:
      state.category = {};
      state.loadings.category = true;
      return state;

    case getWPCategory.types.SUCCESS:
      state.category = action.payload.data;
      state.loadings.category = false;
      return state;

    case getWPCategory.types.FAIL:
      state.loadings.category = false;
      return state;

    // GET POST
    case getWPPage.types.START:
      state.loadings.main = true;
      return state;

    case getWPPage.types.SUCCESS:
      state.pageData = action.payload.data;
      state.loadings.main = false;
      return state;

    case getWPPage.types.FAIL:
      state.loadings.main = false;
      return state;

    // GET NEWS
    case getNews.types.START:
      state.loadings.main = true;
      return state;

    case getNews.types.SUCCESS:
    case getNews.types.FAIL:
      state.loadings.main = false;
      return state;

    default:
      return state;
  }
});

export default reducer;
