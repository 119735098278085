import axios from 'axios';
import { put, call } from 'redux-saga/effects';

// CONSTANTS
import {
  HEALTH_CHECK,
  EMAIL_UNSUBSCRIBE,
  CREATE_FEEDBACK,
  GET_DISCOVER_SEARCH,
  DICTIONARY,
  APP_CONTACT_US_REQUEST_DEMO,
  APP_GET_DOWNLOAD_APP_LINK_VIA_SMS,
} from '@/constants/api';

// UTILITY
import { analyticsIdentify, customAnalyticsEvent } from '@/utility/analytics';
import { showErrorNotification } from '@/utility/saga';
import { getSerializedParams } from '@/utility/routes';

// STORE
import { AppActions, NotifActions } from '../actions';

// LOCALIZATION
import { t } from '@/locale/i18n';

const {
  healthCheck,
  emailUnsubscribe,
  createFeedback,
  getAppSearch,
  getDictionary,
  requestDemo,
  getDownloadAppLinkViaSMS,
} = AppActions;

const { pushSuccessNotificationAction } = NotifActions;

const audienceId = process.env.REACT_APP_MAILCHIMP_AUDIENCE_ID;

export function* healthCheckSaga() {
  yield put(healthCheck.start());

  try {
    yield axios.get(HEALTH_CHECK);

    yield put(healthCheck.success());
  } catch (error) {
    yield put(healthCheck.fail());
  }
}

export function* emailUnsubscribeSaga(action) {
  yield put(emailUnsubscribe.start());

  const { email } = action.payload;

  try {
    const { data: respData } = yield axios.post(EMAIL_UNSUBSCRIBE, { email });

    yield put(emailUnsubscribe.success());

    customAnalyticsEvent('Email Unsubscribed', {
      email,
    });

    analyticsIdentify(
      email,
      {
        email,
      },
      {
        integrations: {
          MailChimp: {
            listId: audienceId,
            subscriptionStatus: 'unsubscribed',
          },
        },
      },
    );

    yield put(
      pushSuccessNotificationAction(
        respData.message || t('notification.successfullyUnsubscribedEmail'),
        { autoHideDuration: undefined },
      ),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(emailUnsubscribe.fail());
  }
}

export function* createFeedbackSaga(action) {
  yield put(createFeedback.start());

  const { data, callback } = action.payload;

  try {
    const { data: respData } = yield axios.post(CREATE_FEEDBACK, data);

    if (callback) callback();

    yield put(createFeedback.success());

    if (respData.message) {
      yield put(pushSuccessNotificationAction(respData.message, { autoHideDuration: undefined }));
    }
  } catch (error) {
    yield put(createFeedback.fail());
  }
}

export function* getAppSearchSaga(action) {
  yield put(getAppSearch.start(action.payload));

  const { params } = action.payload;
  const serializedParams = getSerializedParams(params);

  try {
    let respData = { data: [] };

    if (params.search_string) {
      respData = yield axios.get(GET_DISCOVER_SEARCH, serializedParams).then(res => res.data);
    }

    yield put(getAppSearch.success(respData));
  } catch (error) {
    yield put(getAppSearch.fail());
  }
}

export function* getDictionarySaga() {
  yield put(getDictionary.start());

  try {
    const { data: respData } = yield axios.get(DICTIONARY);

    yield put(getDictionary.success(respData));
  } catch (error) {
    yield put(getDictionary.fail());
  }
}

export function* requestDemoSaga(action) {
  yield put(requestDemo.start());

  const { email, onSuccess } = action.payload;

  try {
    yield axios.post(APP_CONTACT_US_REQUEST_DEMO, { email });

    yield put(requestDemo.success());

    if (onSuccess) onSuccess();
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(requestDemo.fail());
  }
}

export function* getDownloadAppLinkViaSMSSaga(action) {
  yield put(getDownloadAppLinkViaSMS.start());

  const { data, onSuccess } = action.payload;

  try {
    yield axios.post(APP_GET_DOWNLOAD_APP_LINK_VIA_SMS, data);

    yield put(getDownloadAppLinkViaSMS.success());

    if (onSuccess) onSuccess();
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getDownloadAppLinkViaSMS.fail());
  }
}
