import { matchPath, generatePath } from 'react-router-dom';
import { stringify, stringifyUrl, parse } from 'query-string';
import { lazy } from 'react';

// UTILITY
import bugsnag from '@/utility/bugsnagClient';
import { getPartnerMatchPath, generateExpectedPartnerRoute } from '@/utility/partner';
import localStore from '@/utility/store';
import { clearCache } from '@/utility/cache';

// CONSTANTS
import {
  ROUTE_ROOT,
  ROUTE_PARTNER,
  ROUTE_PARTNER_HOME,
  ROUTE_PARTNER_ROOT_HUB,
} from '@/constants/routes';

// COMPONENTS
import { semverGreaterThan } from '@/hoc/CacheBuster';

// STORE
import store from '@/store/configureStore';
import { axiosPublic } from '@/store/configDefaultAPI';

export const getLink = (route, params = {}) => {
  let updatedRoute = route;

  try {
    updatedRoute = generatePath(route, params);
  } catch {
    Object.keys(params).forEach(param => {
      updatedRoute = updatedRoute.replace(`:${param}`, params[param]);
    });
  }

  return updatedRoute;
};

export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(async () => {
          if (retriesLeft === 4) {
            try {
              await axiosPublic
                .get('meta.json')
                .then(response => response.data)
                .then(meta => {
                  const latestVersion = meta.version;
                  const currentVersion = global.appVersion;

                  const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);

                  if (shouldForceRefresh) {
                    return window.location.reload(true);
                  }
                });
            } catch (e) {
              bugsnag.notify(new Error('Error while receiving meta.json', { cause: e }));
            }
          }

          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

function lazyWithPrefetch(factory) {
  const Component = lazy(factory);
  Component.prefetch = factory;
  return Component;
}

export const lazyWithRetry = componentImport =>
  lazyWithPrefetch(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      localStore.get('page-has-been-force-refreshed') || 'false',
    );

    try {
      const component = await componentImport();

      localStore.set('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        localStore.set('page-has-been-force-refreshed', 'true');
        return clearCache();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

/**
 * Paste any string which be converted to array by separator
 * @param {sting} string
 * @param {sting} separator
 * @returns {array} Array
 */
export function parseStingToArray(string, separator, type = 'string') {
  let array = string
    .toString()
    .toLowerCase()
    .split(separator);

  if (type === 'number') {
    array = array.map(el => (!Number.isNaN(+el) ? Number(el) : el.split('_').length > 1 ? el : ''));
  }

  return array;
}

export const isHomeLocation = (pathname = window.location.pathname) => {
  const state = store.getState();
  const partner = state.app.partner;

  const matchPathData = matchPath(
    {
      path: ROUTE_ROOT,
      strict: true,
    },
    pathname,
  );

  let matchPartnerPathData1 = null;
  let matchPartnerPathData2 = null;
  let matchPartnerPathData3 = null;

  if (partner) {
    matchPartnerPathData1 = matchPath(
      {
        path: ROUTE_PARTNER,
        strict: true,
      },
      pathname,
    );

    matchPartnerPathData2 = matchPath(
      {
        path: ROUTE_PARTNER_HOME,
        strict: true,
      },
      pathname,
    );

    matchPartnerPathData3 = matchPath(
      {
        path: ROUTE_PARTNER_ROOT_HUB,
        strict: true,
      },
      pathname,
    );
  }

  const match =
    matchPathData || matchPartnerPathData1 || matchPartnerPathData2 || matchPartnerPathData3;

  return !!match;
};

export const routeEnhance = (link = '', options = {}) => {
  const state = store.getState();
  const { unique_id } = state.auth;
  const {
    isIframe,
    colorSchema,
    isSimpleView,
    isInAppView,
    hasPartnerParam,
    partner,
    useSlug,
    hasNoCacheParam,
    ytpc,
  } = state.app;
  const { isPartnerDomain, partner_id } = state.partner;
  const linkType = typeof link;

  if (!(linkType === 'string' || linkType === 'object')) {
    return link;
  }

  let qsParams = {};
  let linkSearch = '';
  const results = {
    pathname: '',
    search: '',
    state: {
      from: window.location.pathname,
      from_page_path: window.location.pathname,
      from_search_params: window.location.search,
      partner: '',
      partner_id,
      unique_id,
      fromHome: false,
      ...(options.state || {}),
    },
  };

  if (linkType === 'object') {
    results.pathname = link.pathname;
    linkSearch = link.search || '';

    if (typeof link.state === 'object') {
      results.state = {
        ...results.state,
        ...link.state,
      };
    }
  }

  if (linkType === 'string') {
    let updatedLink = link || '';
    let linkHash = '';

    if (updatedLink.includes('#')) {
      linkHash = `#${updatedLink.split('#')[1]}`;
      updatedLink = updatedLink.split('#')[0];

      results.hash = linkHash;
    }

    const linkSplitted = updatedLink.split('?') || [];

    results.pathname = linkSplitted[0] || window.location.pathname;
    linkSearch = linkSplitted[1] || '';
  }

  if (linkSearch) {
    qsParams = parse(linkSearch, {
      arrayFormat: 'comma',
      skipEmptyString: true,
    });
  }

  if (isIframe && !options.withoutIframe) {
    qsParams.iframe = true;
  }

  if (colorSchema) {
    qsParams['c-schema'] = colorSchema;
  }

  if (isSimpleView) {
    qsParams.tv = true;
  }

  if (isInAppView) {
    qsParams.in_app = true;
  }

  if (hasNoCacheParam) qsParams['no-cache'] = true;

  if (ytpc) qsParams.ytpc = ytpc;

  if (!qsParams.partner && hasPartnerParam) {
    qsParams.partner = partner;
  }

  if (isPartnerDomain) {
    delete qsParams.partner;
  }

  if (useSlug && partner && partner !== 'start-now') {
    if (!isPartnerDomain) {
      const expectedPartnerRoute = generateExpectedPartnerRoute(results.pathname, partner);

      const route = getPartnerMatchPath(expectedPartnerRoute);

      if (route) {
        results.pathname = getLink(route.pattern.path, { ...route.params, partner });
        delete qsParams.partner;
      } else {
        results.state.partner = qsParams.partner;
      }
    }
  } else {
    delete qsParams.partner;
  }

  const search = stringify(qsParams, {
    arrayFormat: 'comma',
    skipEmptyString: true,
  }).replace(/=true/gm, '');

  results.search = search;
  return results;
};

export const pagePartnerProtector = (partner, configKeys = [], redirectPath = ROUTE_ROOT) => {
  if (partner.slug) {
    const partnerSettings = partner.settings || {};
    const allowed = configKeys.reduce((acc, key) => acc && !!partnerSettings[key], true);

    if (!allowed) {
      if (window.location.pathname !== routeEnhance(redirectPath).pathname) {
        window.history.replaceState(null, undefined, routeEnhance(redirectPath).pathname);
        window.location.reload();
      }
    }
  }
};

export const getSerializedParams = (params = {}) => {
  return {
    params,
    paramsSerializer: paramsData => {
      return stringify(paramsData, {
        arrayFormat: 'bracket',
        skipEmptyString: true,
      });
    },
  };
};

export const getStringifiedUrl = (url, query = {}, options = {}) => {
  return stringifyUrl(
    {
      url,
      query,
    },
    { skipEmptyString: true, ...options },
  );
};

export const getReferrer = () => {
  try {
    return document.referrer ? document.referrer.split('/')[2] : '';
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('document is likely dead');
    return '';
  }
};

export const checkIsAbsoluteUrl = url => {
  const absoluteRegExp = new RegExp('^(?:[a-z+]+:)?//', 'i');

  return absoluteRegExp.test(url);
};

export default getLink;
