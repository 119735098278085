import produce from 'immer';

// UTILITY
import { addWatchlists, removeWatchlists, updateUserContentStatus } from '@/utility/content';

import { changeContentStatus } from '../actions/content';

import {
  addToWatchList,
  addToDefWatchList,
  removeFromWatchList,
  cleanWatchList,
  getWatchList,
  getWatchListContent,
  copyWatchListContent,
  getWatchlists,
  cleanWatchlists,
  updateWatchlist,
  deleteWatchlist,
  createWatchlist,
  manageAddToWLDialogState,
} from '../actions/watchList';

const initialState = {
  watchlists: [],
  data: {},
  list: {
    data: [],
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 12,
    },
  },
  addToWLDialogIsOpen: false,
  addToWLDialogProps: {},
  loadings: {
    watchlists: false,
    data: true,
    list: true,
    listOnScroll: false,
    action: false,
    actions: {},
    copyWatchList: false,
    update: false,
    delete: false,
    create: false,
  },
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // CLEAN WATCH LIST
    case cleanWatchList.types.INIT: {
      let cleanData = true;

      if (action.payload) {
        cleanData = action.payload.cleanData ?? cleanData;
      }

      if (cleanData) state.data = initialState.data;

      state.list = initialState.list;
      state.loadings.list = initialState.loadings.list;
      return state;
    }

    // ADD TO WATCH LIST
    case addToWatchList.types.START:
      state.loadings.action = true;
      state.loadings.actions[action.payload.content_id] = true;
      return state;

    case addToWatchList.types.SUCCESS: {
      const { content_id, watch_lists, contentData, isSharedList } = action.payload;
      const { list } = state;

      if (list.meta.last_page === 1 && contentData && !isSharedList) {
        state.list.data.push(contentData);
      }

      state.list.data = list.data.map(item => {
        if (item.id === content_id) {
          const watchlists = addWatchlists(item.watchlists, watch_lists);

          return { ...item, watchlists, in_watchlist: !!watchlists.length };
        }
        return item;
      });

      state.watchlists = state.watchlists.map(watchlist => {
        if (
          !!watchlist.content &&
          watch_lists.includes(watchlist.id) &&
          contentData &&
          !watchlist.hasMoreContent
        ) {
          watchlist.content.push(contentData);
        }

        if (watchlist.content) {
          return {
            ...watchlist,
            content: watchlist.content.map(content => {
              if (content.id === content_id) {
                const watchlists = addWatchlists(content.watchlists, watch_lists);

                return { ...content, watchlists, in_watchlist: !!watchlists.length };
              }

              return content;
            }),
          };
        }

        return watchlist;
      });

      // add watchlist to dialog
      if (state.addToWLDialogProps.data && state.addToWLDialogProps.data.id === content_id) {
        state.addToWLDialogProps.data.watchlists = addWatchlists(
          state.addToWLDialogProps.data.watchlists,
          watch_lists,
        );
      }

      return state;
    }

    case addToWatchList.types.STOP:
    case addToWatchList.types.FAIL:
      state.loadings.action = false;
      state.loadings.actions[action.payload.content_id] = false;
      return state;

    // ADD TO DEFAULTS WATCHLIST
    case addToDefWatchList.types.START:
      state.loadings.action = true;
      state.loadings.actions[action.payload.content_id] = true;
      return state;

    case addToDefWatchList.types.SUCCESS:
    case addToDefWatchList.types.FAIL:
      state.loadings.action = false;
      state.loadings.actions[action.payload.content_id] = false;
      return state;

    // REMOVE FROM WATCH LIST
    case removeFromWatchList.types.START:
      state.loadings.action = true;
      state.loadings.actions[action.payload.content_id] = true;
      return state;

    case removeFromWatchList.types.SUCCESS:
      if (action.payload.isSharedList) {
        state.list.data = state.list.data.map(item => {
          if (item.id === action.payload.content_id) {
            const watchlists = removeWatchlists(item.watchlists, action.payload.watch_lists);

            return { ...item, watchlists, in_watchlist: !!watchlists.length };
          }
          return item;
        });
      } else {
        state.list.data = state.list.data.filter(item => item.id !== action.payload.content_id);
        state.watchlists = state.watchlists.map(watchlist => {
          if (watchlist.content && action.payload.watch_lists.includes(watchlist.id)) {
            return {
              ...watchlist,
              content: watchlist.content.filter(
                content => content.id !== action.payload.content_id,
              ),
            };
          }

          if (watchlist.content) {
            return {
              ...watchlist,
              content: watchlist.content.map(content => {
                if (content.id === action.payload.content_id) {
                  const watchlists = removeWatchlists(
                    content.watchlists,
                    action.payload.watch_lists,
                  );

                  return { ...content, watchlists, in_watchlist: !!watchlists.length };
                }

                return content;
              }),
            };
          }

          return watchlist;
        });
      }

      // remove watchlist from dialog
      if (
        state.addToWLDialogProps.data &&
        state.addToWLDialogProps.data.id === action.payload.content_id
      ) {
        state.addToWLDialogProps.data.watchlists = removeWatchlists(
          state.addToWLDialogProps.data.watchlists,
          action.payload.watch_lists,
        );
      }

      return state;

    case removeFromWatchList.types.STOP:
    case removeFromWatchList.types.FAIL:
      state.loadings.action = false;
      state.loadings.actions[action.payload.content_id] = false;
      return state;

    // CHANGE CONTENT STATUS
    case changeContentStatus.types.SUCCESS: {
      const { content_id, status } = action.payload;

      state.list.data = state.list.data.map(item =>
        updateUserContentStatus(item, content_id, status),
      );
      state.watchlists = state.watchlists.map(watchlist => {
        if (!watchlist.content) return watchlist;

        return {
          ...watchlist,
          content: watchlist.content.map(content =>
            updateUserContentStatus(content, content_id, status),
          ),
        };
      });

      return state;
    }

    // GET WATCH LIST
    case getWatchList.types.START:
      state.loadings.data = true;
      return state;

    case getWatchList.types.SUCCESS:
      state.loadings.data = false;
      state.data = action.payload.data;
      return state;

    case getWatchList.types.FAIL:
      state.loadings.data = false;
      return state;

    // GET WATCH LIST CONTENT
    case getWatchListContent.types.START:
      state.loadings.list = true;
      state.loadings.listOnScroll = action.payload.onScroll;
      return state;

    case getWatchListContent.types.SUCCESS:
      state.list.data =
        action.payload.meta.current_page === 1
          ? action.payload.data
          : state.list.data.concat(action.payload.data);
      state.list.meta.current_page = action.payload.meta.current_page;
      state.list.meta.last_page = action.payload.meta.last_page;
      state.list.meta.per_page = action.payload.meta.per_page;
      state.loadings.list = false;
      state.loadings.listOnScroll = false;
      return state;

    case getWatchListContent.types.STOP:
    case getWatchListContent.types.FAIL:
      state.loadings.list = false;
      state.loadings.listOnScroll = false;
      return state;

    // COPY WATCH LIST
    case copyWatchListContent.types.START:
      state.loadings.copyWatchList = true;
      return state;

    case copyWatchListContent.types.SUCCESS:
      state.data.copied_watch_list = action.payload.data;
      state.loadings.copyWatchList = false;
      return state;

    case copyWatchListContent.types.FAIL:
      state.loadings.copyWatchList = false;
      return state;

    // GET WATCHLISTS
    case getWatchlists.types.START:
      state.loadings.watchlists = true;
      return state;

    case getWatchlists.types.SUCCESS:
      state.watchlists = action.payload.data;
      state.loadings.watchlists = false;
      return state;

    case getWatchlists.types.FAIL:
      state.loadings.watchlists = false;
      return state;

    // CLEAN WATCHLISTS
    case cleanWatchlists.types.INIT:
      state.watchlists = initialState.watchlists;
      state.list = initialState.list;
      state.loadings.list = initialState.loadings.list;
      return state;

    // UPDATE WATCHLISTS
    case updateWatchlist.types.START:
      state.loadings.update = true;
      return state;

    case updateWatchlist.types.SUCCESS:
      state.data = { ...state.data, ...action.payload.data };
      state.watchlists = state.watchlists.map(wl =>
        wl.id === action.payload.id ? { ...wl, ...action.payload.data } : wl,
      );
      state.loadings.update = false;
      return state;

    case updateWatchlist.types.FAIL:
      state.loadings.update = false;
      return state;

    // DELETE WATCHLIST
    case deleteWatchlist.types.START:
      state.loadings.delete = true;
      return state;

    case deleteWatchlist.types.SUCCESS:
      state.watchlists = state.watchlists.filter(watchlist => watchlist.id !== action.payload.id);
      state.loadings.delete = false;
      return state;

    case deleteWatchlist.types.FAIL:
      state.loadings.delete = false;
      return state;

    // CREATE WATCHLIST
    case createWatchlist.types.START:
      state.loadings.create = true;
      return state;

    case createWatchlist.types.SUCCESS:
      state.watchlists.push(action.payload.data);
      state.loadings.create = false;
      return state;

    case createWatchlist.types.FAIL:
      state.loadings.create = false;
      return state;

    // MANAGE ADD TO WATCHLIST DIALOG STATE
    case manageAddToWLDialogState.types.INIT:
      state.addToWLDialogIsOpen = action.payload.state;
      state.addToWLDialogProps = action?.payload?.props || {};
      return state;

    default:
      return state;
  }
});

export default reducer;
