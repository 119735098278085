export const SubscriptionStatus = {
  ACTIVE: 'active',
  TRIALING: 'trialing',
  PENDING: 'pending',
  INCOMPLETE: 'incomplete',
  FAILED: 'failed',
  CANCELED: 'canceled',
  PAST_DUE: 'past_due',
};

export const TransactionStatus = {
  PAID: 'paid',
  PENDING: 'pending',
  FAILED: 'failed',
};

export const PaymentVariant = {
  VIRTUAL_CARD: 'virtual_card',
  CARD: 'card',
};

export const SubscriptionFlow = {
  DEFAULT: 'default',
  DEFERRED_REGISTRATION: 'deferred_registration',
};

export const SubscriptionType = {
  APP: 'app',
  BUNDLE: 'bundle',
};

export default { SubscriptionStatus };
