import axios from 'axios';
import { put, select, call } from 'redux-saga/effects';

// CONSTANTS
import {
  GET_CSR_RESULTS,
  GET_RESULTS,
  RESULTS_ADD_EMAIL,
  RESULTS_SENT_TOKEN,
  APPS_BY_IDS,
  SERVICES_CHANNELS_BY_IDS,
} from '@/constants/api';

// UTILITY
import { getLink, getSerializedParams } from '@/utility/routes';
import {
  analyticsIdentify,
  customAnalyticsEvent,
  analyticsDynamicEvent,
} from '@/utility/analytics';
import { showErrorNotification } from '@/utility/saga';

// STORE
import { ResultsActions, UserActions, NotifActions } from '@/store/actions';
import { AppSelectors, AuthSelectors } from '@/store/selectors';

// LOCALIZATION
import { t } from '@/locale/i18n';

const {
  getResults,
  addEmailToResults,
  sendTokenForGetResults,
  getAppByIds,
  getChannelsByServicesIds,
} = ResultsActions;
const { userGetContentCount } = UserActions;
const { pushSuccessNotificationAction } = NotifActions;

export function* getResultsSaga(action) {
  yield put(getResults.start());

  const { unique_id, isCSR = false, only_local_feed = 0 } = action.payload;
  const endpoint = isCSR ? GET_CSR_RESULTS : GET_RESULTS;
  const isAuth = yield select(AuthSelectors.getIsAuth);
  const ytpc = yield select(AppSelectors.getYtpc);
  const serializedParams = getSerializedParams({
    ytpc,
  });
  const url = getLink(endpoint, { unique_id });

  if (only_local_feed === 1) {
    yield axios.put(url, { only_local_feed });
  }

  try {
    const { data: respData } = yield axios.get(url, serializedParams);

    if (respData.email_send && respData.features) {
      analyticsDynamicEvent({
        activity_name: 'Received Email With This Callout',
        feature_1: respData.features?.ad_name || '',
        feature_2: respData.features?.ad_to_show || '',
        feature_3: respData.features?.unique_id || '',
      });
    }

    if (isAuth) {
      yield put(userGetContentCount.init());
    }

    yield put(
      getResults.success({
        data: {
          ...respData.bundles,
          internet_options: respData.internet_options,
          show_csr_internet_option: respData.show_csr_internet_option,
          user_exist: respData.user_exist,
          recommended_block: respData.recommended_block,
          banner: respData.banner,
          view: respData.view,
        },
      }),
    );
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(getResults.fail());
  }
}

export function* addEmailToResultsSaga(action) {
  yield put(addEmailToResults.start());

  const { unique_id, email } = action.payload;
  const ytpc = yield select(AppSelectors.getYtpc);
  const serializedParams = getSerializedParams({
    ytpc,
  });

  try {
    yield axios.post(RESULTS_ADD_EMAIL, { unique_id, email }, serializedParams);

    yield put(
      addEmailToResults.success({
        email,
      }),
    );

    customAnalyticsEvent('Added Email Address Later', {
      email,
    });

    analyticsIdentify(email, {
      email,
    });

    yield put(pushSuccessNotificationAction(t('notification.successfullyAddedEmail')));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(addEmailToResults.fail());
  }
}

export function* sentTokenForGetResultsSaga(action) {
  yield put(sendTokenForGetResults.start());

  const { email } = action.payload;
  const url = getLink(RESULTS_SENT_TOKEN, { email });

  try {
    const { data: respData } = yield axios.get(url);

    yield put(pushSuccessNotificationAction(respData.message));

    yield put(sendTokenForGetResults.success());
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(sendTokenForGetResults.fail());
  }
}

export function* getAppByIdsSaga(action) {
  yield put(getAppByIds.start());

  const { params } = action.payload;
  const partner = yield select(AppSelectors.getPartner);

  if (partner) {
    params.partner = partner;
  }

  const serializedParams = getSerializedParams(params);

  try {
    const { data: respData } = yield axios.get(APPS_BY_IDS, serializedParams);

    yield put(getAppByIds.success(respData));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(sendTokenForGetResults.fail());
  }
}

export function* getChannelsByServicesIdsSaga(action) {
  yield put(getChannelsByServicesIds.start());

  const { ids, dma_id } = action.payload;
  const url = getLink(SERVICES_CHANNELS_BY_IDS, { ids: ids.join(), dma_id });

  try {
    const { data: respData } = yield axios.get(url);

    yield put(getChannelsByServicesIds.success(respData));
  } catch (error) {
    yield call(showErrorNotification, error);

    yield put(sendTokenForGetResults.fail());
  }
}
