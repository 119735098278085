import axios from 'axios';
import axiosRetry from 'axios-retry';
import { setupCache } from 'axios-cache-adapter/src/api';
import storage from 'localforage';
import { isMobile } from 'react-device-detect';

// LOCALIZATION
import { getCurrentLang } from '@/locale/i18n';

// CONSTANTS
import {
  DICTIONARY,
  AUTH_GET_USER,
  AUTH_REFRESH_TOKEN,
  HEALTH_CHECK,
  USER_GET_CONTENT_COUNT,
  USER_GET_APPS,
  GET_UNIQUE_ID,
  PROMOTION_CHECK_CODE_STATUS,
  PROMOTION_GET_DETAILS,
  INTERNET_OFFERS_CHECK_SUBSCRIPTION,
  VIRTUAL_CARD_GET_EXPANDED_DATA,
  INTERNET_OFFERS_GET_RESULT,
  USER_PAYMENT_HISTORY_GET,
  USER_STREAMING_REWARD_GET,
  USER_CARDS,
  WATCH_LIST_GET_LIST,
} from '@/constants/api';

// UTILITY
import { routeEnhance, getLink } from '@/utility/routes';
import LocalStore from '@/utility/store';
import { analyticsRecievedError } from '@/utility/analytics';
import getErrorMessage from '@/utility/errors';
import { serializeCacheKey } from '@/utility/cache';
import { getSearchParams, localStorageAvailable } from '@/utility/common';
import bugsnag from '@/utility/bugsnagClient';

// STORE
import { setMaintenanceMode } from '@/store/actions/app';
import { logout } from '@/store/actions/auth';
import store from '@/store/configureStore';

const env = process.env.REACT_APP_ENV;
const isDevEnv = env === 'develop';
const params = getSearchParams(document.location.search);
const fivetranBaseURL = 'https://webhooks.fivetran.com/';
const isStorageAvailable = localStorageAvailable();
const isNoCache = params ? params.has('no-cache') : false;

const devCacheExclide = isDevEnv ? [DICTIONARY] : [];

storage.config({ storeName: 'mybundle-api-responses' });
// set Cache configs
const cache = setupCache({
  maxAge: 60 * 60 * 1000, // 1 hour
  exclude: {
    query: false,
    paths: [
      ...devCacheExclide,
      'meta.json',
      AUTH_GET_USER,
      AUTH_REFRESH_TOKEN,
      HEALTH_CHECK,
      USER_GET_CONTENT_COUNT,
      USER_GET_APPS,
      GET_UNIQUE_ID,
      PROMOTION_CHECK_CODE_STATUS,
      getLink(PROMOTION_GET_DETAILS, { promo_slug: 'curiosity-promo' }),
      INTERNET_OFFERS_CHECK_SUBSCRIPTION,
      VIRTUAL_CARD_GET_EXPANDED_DATA,
      INTERNET_OFFERS_GET_RESULT.replace('/:io_id', ''),
      USER_PAYMENT_HISTORY_GET,
      USER_STREAMING_REWARD_GET,
      USER_CARDS,
      WATCH_LIST_GET_LIST,
    ],
  },
  key: serializeCacheKey,
  store: storage,
  invalidate: async (config, request) => {
    if (request.clearCacheEntry || request.ignoreCache) {
      await config.store.removeItem(config.uuid);
    }
  },
  // debug: true,
});

// set API configs
axios.defaults.baseURL = process.env.REACT_APP_BASE_API;

if (isStorageAvailable && !isNoCache) {
  axios.defaults.adapter = cache.adapter;
}

axios.defaults.headers.post['x-is-mobile'] = isMobile;
axios.defaults['x-is-mobile'] = isMobile;

const isNetworkError = error =>
  error && // just to make sure
  !error.response && // if there is a response, it reached the server and not a network error
  error.code !== 'ECONNABORTED';

function injectAxiosRetry(axiosInstance, config) {
  axiosRetry(axiosInstance, {
    retries: 1,
    retryDelay: () => 5000,
    shouldResetTimeout: true,
    retryCondition: error => {
      return (
        isNetworkError(error) ||
        (axiosRetry.isNetworkOrIdempotentRequestError(error) && error.response?.status !== 519)
      );
    },
    ...config,
  });
}

export const initAxiosRetry = () => {
  injectAxiosRetry(axios);
};

export const deleteAuthorizationToken = () => {
  delete axios.defaults.headers.Authorization;
};

export const updateHeadersLang = lng => {
  axios.defaults.headers['X-localization'] = lng;
};

export const setAuthorizationToken = (token, tokenType) => {
  const lang = getCurrentLang();

  if (token) {
    axios.defaults.headers = {
      ...axios.defaults.headers,
      Authorization: `${tokenType} ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-localization': lang,
    };
  } else {
    deleteAuthorizationToken();
  }
};

export const createAxiosResponseInterceptor = () => {
  const interceptor = axios.interceptors.response.use(
    response => response,
    error => {
      const errorResponse = error?.response || {};

      const properties = {
        url: errorResponse?.config?.url || errorResponse?.responseURL,
        status: errorResponse?.status,
        message: getErrorMessage(error, false),
        params: errorResponse?.config?.data || JSON.stringify(errorResponse?.config?.params),
        method: errorResponse.config?.method,
      };

      analyticsRecievedError(properties);

      if (errorResponse?.status !== 401) {
        if (errorResponse?.status === 519) {
          store.dispatch({ type: setMaintenanceMode.types.INIT, payload: true });

          if (window.location.pathname !== routeEnhance('/').pathname) {
            window.location.href = routeEnhance('/').pathname;
          }
        }

        // Reject promise if usual error
        return Promise.reject(error);
      }

      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      axios.interceptors.response.eject(interceptor);

      return axios
        .get(AUTH_REFRESH_TOKEN)
        .then(response => {
          error.config.headers.Authorization = `${response.data.token_type} ${response.data.token}`;
          setAuthorizationToken(response.data.token, response.data.token_type);
          LocalStore.set('token', response.data.token);

          return axios(error.response.config);
        })
        .catch(err => {
          if (isDevEnv) {
            bugsnag.notify(new Error('Logout - Refresh token', { cause: err }), event => {
              event.addMetadata('props', properties);
            });
          }
          store.dispatch({ type: logout.types.INIT });
          return Promise.reject(err);
        })
        .finally(createAxiosResponseInterceptor);
    },
  );
};

export const axiosWP = axios.create({
  baseURL: process.env.REACT_APP_WP_API,
});
delete axiosWP.defaults['x-is-mobile'];
delete axiosWP.defaults.headers.post['x-is-mobile'];

export const axiosFivetran = axios.create({
  baseURL: fivetranBaseURL,
});

delete axiosFivetran.defaults['x-is-mobile'];
delete axiosFivetran.defaults.headers.post['x-is-mobile'];

export const axiosPublic = axios.create({
  baseURL: window.location.origin,
});

injectAxiosRetry(axiosFivetran, {
  retries: 2,
  retryDelay: () => 1000,
  retryCondition: error => {
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || error.config?.method === 'post';
  },
});
injectAxiosRetry(axiosPublic);

axiosFivetran.interceptors.request.use(config => {
  if (config['axios-retry']) {
    const retryCount = config['axios-retry'].retryCount;
    const data = JSON.parse(config.data);
    data.retry_count = retryCount;
    config.data = JSON.stringify(data);
  }

  return config;
});
