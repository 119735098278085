export default {
  title: {
    voter: 'Help Your Friend Decide',
    submitter: 'Let Your Friends Help',
  },
  subtitle:
    'After selecting a list of shows, share it with your friends so they can vote on what you should watch next.',
  subtitle2:
    "Vote on shows you've liked/disliked or think your friend will enjoy watching. You can also suggest a show and we'll add it to their list.",
  noResults: 'Sorry, we dont have any more shows to recommend. Click ',
  noResultsPart2: ' to try a different search',
  noResultsVoter: 'There are no shows for this search, click ',
  noResultsVoterPart2: ' to start a new search',
  pageDescription: 'Help me decide what to watch next. {{content}} Vote here!',

  recommend: {
    title: 'Recommend Movies & Shows',

    askMyFriend: {
      title: 'Ask My friend List',
    },
    content: {
      title: 'Select movies & shows to include in the list',
    },
  },
  userNameDialog: {
    title: "What's your name?",
  },
  friendsSuggestions: {
    title: "My Friends' Suggestions",
    titleForFriends: 'Friends Suggestions',
    searchTitle: 'Add more shows',
    searchDialogTitle: 'Search for TV Show to Add',
    searchSubtitle: '',
  },
  voterstartover: {
    title: 'Looking for your next show to watch?',
    subtitle:
      "Tell us what streaming services you have and what you're in the mood for and we'll generate a personalized list of your own for your friends to vote on!",
  },

  intro: {
    userName: 'Your friend',
    title: 'You’ve received some movies & shows voting requests from your friend - {{name}}',
    subtitle: 'Vote thumbs up or down on the shows selected and make your own suggestions back!',
    btnText: 'Start voting',
  },
  completed: {
    userName: 'friend',
    submitter: {
      title: 'Yay! Your votes have been submitted successfully.',
      subtitle: '',
    },
    voter: {
      title: "Great! Your votes are in and <s>{{name}}'s</s> TV experiences just got better!",
      subtitle:
        'Create your own <s>MyBundle</s> Binge-List and let your friends help you find your next show',
    },
    getApp: {
      subtitle:
        'Get the MyBundle App and create your own list to have your friends help you decide what to watch next',
    },
  },
  loaders: {
    default: '',
  },
};
