export default {
  content: {
    title: 'Choose Multiple',
    subtitle: 'Add content to a list that your friends will vote on',
    search: {
      title: 'Search',
    },
    suggested: {
      title: 'Suggested',
    },
  },
  review: {
    title: 'Review Ask My Friends List',
    listName: {
      title: 'Name your Ask My Friends List',
    },
  },
  complete: {
    title: 'Your Ask My Friends List has been created',
    subtitle: 'Share below to get your friends votes on what to watch next',
    share: {
      title: 'Share this link to your friend',
    },
    email: {
      title: 'Enter your email to save and see your friends vote results',
    },
  },
};
