import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core';

// CONSTANTS
import {
  ROUTE_404,
  ROUTE_CHANNELS,
  ROUTE_CSR_CHANNELS,
  ROUTE_PARTNER_CSR,
} from '@/constants/routes';

// UTILITY
import store from '@/utility/store';
import { getLink, routeEnhance } from '@/utility/routes';
import { getPartnerFromPathname } from '@/utility/partner';
import { isPageSpeedBot, getSearchParams } from '@/utility/common';
import { analyticsGetUserId } from '@/utility/analytics';

// STORE
import { PartnerActions, AuthActions, AppActions } from '@/store/actions';

// THEME
import theme, { createNewTheme } from '@/theme/materialTheme';

// HOC
import CacheBuster from '@/hoc/CacheBuster';

// COMPONENTS
import SnackbarProvider from '@/components/SnackbarProvider';
import SnackbarNotify from '@/components/SnackbarNotify';
import Loader from '@/components/Loader';
import AuthDrawer from '@/components/Drawers/AuthDrawer';
import ProfileDrawer from '@/components/Drawers/ProfileDrawer';
import DialogAuth from '@/components/Dialogs/DialogAuth';
import DialogAddToWatchlist from '@/components/Dialogs/DialogAddToWatchlist';
import Onboarding from '@/components/Onboarding';

// ROUTES
import Routes from '@/routes';

// STYLES
import GlobalStyle from '@/styles';

const env = process.env.REACT_APP_ENV;
const cloudfront = process.env.REACT_APP_CLOUDFRONT_LINK;
const baseAPI = process.env.REACT_APP_BASE_API
  ? process.env.REACT_APP_BASE_API.replace('api/', '')
  : '';
const isPrerender = window.prerenderCloudIsServerSideRendering;

// const referrerLink = document.referrer;

// if (referrerLink.includes('instagram') || referrerLink.includes('facebook')) {
// Cookies.set('show_optional', '1', { expires: 2 });
// }

Cookies.set('show_optional', '1', { expires: 2 });

function getNewRelicScript() {
  let path = '';

  switch (env) {
    case 'production':
      path = '/js/newrelic/newrelic.js';
      break;
    case 'staging':
      path = '/js/newrelic/newrelic-stg.js';
      break;
    case 'develop':
      path = '/js/newrelic/newrelic-dev.js';
      break;
    default:
      return null;
  }

  if (path) return <script type="text/javascript" src={path} />;
}

const getIsRouteShouldUseStartData = () => {
  const pathname = document.location.pathname;
  const regex = /\/partner\/[a-zA-Z]+/;
  const isPartnerLink = regex.exec(pathname);

  let isMatch = false;
  if (isPartnerLink) {
    isMatch = true;
  } else {
    switch (pathname) {
      case getLink(ROUTE_PARTNER_CSR, { partner: 'start-now' }):
      case ROUTE_CHANNELS:
      case ROUTE_CSR_CHANNELS:
        isMatch = true;
        break;
      default:
        isMatch = false;
    }
  }

  return isMatch;
};

function App(props) {
  const {
    partner,
    partnerSlug,
    partnerLoading,
    formData,
    isIframe,
    iframeType,
    colorSchema,
    headerHeight,
    getPartner,
    isPartnerDomain,
    authDrawerOpen,
    setDeviceOrientation,
    anonymousId,
    setAnonymousId,
    firebaseLoginLoading,
  } = props;
  const [t] = useTranslation();
  const navigate = useNavigate();
  const params = getSearchParams(document.location.search);
  const hasPartnerParam = params ? params.has('partner') : '';
  const hasUtmSourceParam = params ? params.has('utm_source') : '';
  const hasResetPasswordParam = params ? params.has('reset_password') : '';
  const hasForgotPasswordParam = params ? params.has('forgot_password') : '';
  const parterFromPathname = getPartnerFromPathname();
  const isRouteMatch = hasPartnerParam || getIsRouteShouldUseStartData() || !!parterFromPathname;
  const isDevEnv = env === 'local' || env === 'develop';

  const element = document.getElementById('root');
  let startData;
  let slug = '';
  let curTheme = theme;

  try {
    startData = store.get('start-data');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('start data not available');
  }

  if (!isRouteMatch && startData) {
    if (startData?.partner?.slug) {
      store.remove('start-data');
      startData = null;
    }
  }

  if (isPartnerDomain) {
    slug = partner.slug;
  } else {
    slug = parterFromPathname || partner?.slug || (hasPartnerParam ? params.get('partner') : '');
  }

  if (!slug && startData?.partner?.slug) {
    slug = startData.partner.slug;
  }

  if (!slug && formData?.partner?.slug) {
    slug = formData.partner.slug;
  }

  if (element && slug) {
    element.className = '';

    try {
      element.classList.add(slug.replace(' ', ''));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  }

  if (element && isIframe) {
    element.classList.add('iframe');

    if (iframeType) {
      element.classList.add(`iframe--${iframeType}`);
    }
  }

  if (colorSchema) {
    const cls = ['color-schema', `color-schema--${colorSchema}`];

    element.classList.add(...cls);
  }

  if (hasUtmSourceParam) {
    const utm_source = params.get('utm_source');
    const expireTime = new Date(new Date().getTime() + 60 * 60 * 1000); // 60 minutes

    Cookies.set('utm_source', utm_source, { expires: expireTime });
  }

  const handleDeviceOrientation = () => {
    try {
      let orientation = '';
      switch (window.orientation) {
        case 0:
        case 180:
          orientation = 'portrait';
          break;
        case 90:
        case 270:
          orientation = 'landscape';
          break;

        default:
          orientation = 'portrait';
          break;
      }

      setDeviceOrientation(orientation);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  };

  useEffect(() => {
    if (hasResetPasswordParam) authDrawerOpen({ screen: 'updatePassword' });
    if (hasForgotPasswordParam) authDrawerOpen({ screen: 'forgot' });

    handleDeviceOrientation();
    window.addEventListener('orientationchange', handleDeviceOrientation);

    if (!anonymousId && window.analytics) {
      window.analytics.ready(() => {
        const segmentId = analyticsGetUserId();

        setAnonymousId(segmentId);
      });
    }

    return () => window.removeEventListener('orientationchange', handleDeviceOrientation);
  }, []);

  useEffect(() => {
    if (!isPartnerDomain) {
      if (slug) {
        getPartner(slug, () => {
          const enhancedRoute = routeEnhance(ROUTE_404);

          navigate(enhancedRoute, { replace: true, state: enhancedRoute.state });
        });
      }
    }
  }, [slug, isPartnerDomain]);

  if (partner.theme) {
    curTheme = createNewTheme(partner.theme || {});
  }

  const isWowPartner = partner.partner_slug === 'wow';
  const isEPBPartner = partner.partner_slug === 'epb';
  const showPartnerLoader = isPartnerDomain || isIframe || !!partnerSlug;

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
          return null;
        }

        return (
          <div className="App">
            <MuiThemeProvider theme={{ ...curTheme, headerHeight }}>
              <ThemeProvider theme={{ ...curTheme, headerHeight }}>
                <SnackbarProvider>
                  <SnackbarNotify />
                </SnackbarProvider>
                <Helmet>
                  {!isPageSpeedBot() && !isPrerender && getNewRelicScript()}
                  <title>Find Your Streaming Service | MyBundle</title>
                  {!!baseAPI && <link rel="preconnect" href={baseAPI} crossOrigin="anonymous" />}
                  <link rel="preconnect" href="https://pro.ip-api.com" crossOrigin="anonymous" />
                  <meta property="og:title" content="MyBundle - Personalized Streaming TV Advice" />
                  <meta property="og:site_name" content="MyBundle" />
                  <meta
                    property="og:description"
                    content="Stop wasting money on the TV you don't watch.  Get your personalized Streaming TV recommendation with MyBundle for free"
                  />
                  <meta
                    name="description"
                    content="Stop wasting money on the TV you don't watch.  Get your personalized Streaming TV recommendation with MyBundle for free"
                  />
                  <meta property="og:type" content="website" />
                  <meta property="fb:app_id" content="608514030003288" />
                  <meta
                    property="og:image"
                    content={`${cloudfront}/assets/images/og-image/og-default.png`}
                  />
                  <meta name="robots" content="index, follow, max-image-preview:large" />
                  <meta
                    name="twitter:title"
                    content="MyBundle - Personalized Streaming TV Advice"
                  />
                  <meta
                    name="twitter:description"
                    content="Stop wasting money on the TV you don't watch.  Get your personalized Streaming TV recommendation with MyBundle for free"
                  />
                  <meta
                    name="twitter:image"
                    content={`${cloudfront}/assets/images/og-image/og-default.png`}
                  />
                  <meta name="twitter:site" content="@mybundletv" />
                  <meta name="twitter:creator" content="@mybundletv" />
                  <meta name="twitter:card" content="summary" />
                  {isIframe && !isPrerender && (
                    <script type="text/javascript" src="/js/iframeResizer.contentWindow.min.js" />
                  )}
                  {/* Google Tag Manager */}
                  {isWowPartner && !isPrerender && (
                    <script>
                      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer','GTM-MZ9Z84K');`}
                    </script>
                  )}
                  {isWowPartner && isDevEnv && (
                    <script
                      type="text/javascript"
                      src="https://cdn.optimizely.com/js/12142631161.js"
                    />
                  )}
                  {/* End Google Tag Manager */}
                  {/* <!-- Global site tag (gtag.js) - Google Ads: 752979887 --> */}
                  {isEPBPartner && !isPrerender && (
                    <script
                      async
                      type="text/javascript"
                      src="https://www.googletagmanager.com/gtag/js?id=AW-752979887"
                    />
                  )}
                  {isEPBPartner && !isPrerender && (
                    <script>
                      {`window.dataLayer = window.dataLayer || [];
                          function gtag(){ dataLayer.push(arguments);}
                          gtag('js', new Date());
                          gtag('config', 'AW-752979887');
                        `}
                    </script>
                  )}
                </Helmet>
                <Routes />
                <GlobalStyle
                  partner={partner}
                  theme={curTheme}
                  extraStyles={partner.extra_styles}
                  isIframe={isIframe}
                />
                <AuthDrawer />
                <ProfileDrawer />
                <DialogAuth />
                <Onboarding />
                <DialogAddToWatchlist />
                <Loader
                  active={(showPartnerLoader && partnerLoading) || firebaseLoginLoading}
                  fullFilled
                  position="fixed"
                  zIndex="1220"
                  text={partnerLoading ? t('partner.loaders.default') : ''}
                />
              </ThemeProvider>
            </MuiThemeProvider>
          </div>
        );
      }}
    </CacheBuster>
  );
}

App.propTypes = {
  partner: PropTypes.object.isRequired,
  partnerSlug: PropTypes.string.isRequired,
  partnerLoading: PropTypes.bool.isRequired,
  isPartnerDomain: PropTypes.bool.isRequired,
  formData: PropTypes.object,
  partner_id: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  isIframe: PropTypes.bool.isRequired,
  iframeType: PropTypes.string.isRequired,
  colorSchema: PropTypes.string.isRequired,
  headerHeight: PropTypes.number.isRequired,
  getPartner: PropTypes.func.isRequired,
  authDrawerOpen: PropTypes.func.isRequired,
  setDeviceOrientation: PropTypes.func.isRequired,
  setAnonymousId: PropTypes.func.isRequired,
  anonymousId: PropTypes.string.isRequired,
  firebaseLoginLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ partner, channels, app, auth }) => ({
  partner: partner.data,
  partnerLoading: partner.loadings.partner,
  isPartnerDomain: partner.isPartnerDomain,
  formData: channels.formData,
  partner_id: partner.partner_id,
  isIframe: app.isIframe,
  iframeType: app.iframeType,
  colorSchema: app.colorSchema,
  partnerSlug: app.partner,
  headerHeight: app.headerHeight,
  anonymousId: auth.anonymousId,
  firebaseLoginLoading: auth.firebaseLoginLoading,
});

const mapDispatchToProps = dispatch => ({
  getPartner: (slug, onError) => dispatch(PartnerActions.getPartner.action({ slug, onError })),
  authDrawerOpen: data => dispatch(AuthActions.authDrawerOpen.action({ data })),
  setAnonymousId: id => dispatch(AuthActions.setAnonymousId.action({ id })),
  setDeviceOrientation: orientation =>
    dispatch(AppActions.setDeviceOrientation.action({ orientation })),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(App);
