import produce from 'immer';

// UTILITY
import {
  getProductByPlanId,
  getPlanById,
  getPlan,
  getAddonsByIds,
  getTotalPrice,
} from '@/utility/subscription';
import { addWatchlists, removeWatchlists, updateUserContentStatus } from '@/utility/content';

import { addToWatchList, removeFromWatchList } from '@/store/actions/watchList';
import { changeContentStatus } from '@/store/actions/content';
import {
  getSubscriptionDetails,
  getSubscriptionDictionary,
  updateSubscriptionDictionary,
  getSubscriptionTransactions,
  addSubscriptionPayment,
  addSubscriptionRegistration,
  registerSubscriptionService,
  cancelSubscription,
  getSubscriptionPreview,
  clearSubscriptionTransactions,
  clearSubscriptionDetails,
  renewSubscription,
  manageSubscriptionAuthDialogState,
  changePassword,
  clearRegisterSubscriptionErrors,
} from '../actions/subscription';
import { userChangeAppPrice } from '../actions/user';

const initialState = {
  subscriptionDetails: null,
  dictionary: null,
  transactions: {
    data: [],
    meta: {
      current_page: 1,
      last_page: 1,
      per_page: 5,
    },
  },
  preview: {},
  loadings: {
    transactions: false,
    dictionary: false,
    subscriptionDetails: false,
    locast: false,
    payment: false,
    registration: false,
    cancel: false,
    renew: false,
    createCard: false,
    preview: false,
    changePassword: false,
  },
  errors: {
    registration: null,
    payment: null,
    changePassword: null,
  },
  authDialogIsOpen: false,
  authDialogProps: {},
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    // GET SUBSCRIPTION DETAILS
    case getSubscriptionDetails.types.START:
      state.subscriptionDetails = null;
      state.loadings.subscriptionDetails = true;
      return state;

    case getSubscriptionDetails.types.SUCCESS:
      state.subscriptionDetails = action.payload.data;
      state.loadings.subscriptionDetails = false;
      return state;

    case getSubscriptionDetails.types.FAIL:
      state.subscriptionDetails = null;
      state.loadings.subscriptionDetails = false;
      return state;

    case clearSubscriptionDetails.types.INIT:
      state.subscriptionDetails = initialState.subscriptionDetails;
      state.loadings.subscriptionDetails = initialState.loadings.subscriptionDetails;
      return state;

    // GET SUBSCRIPTION DICTIONARY
    case getSubscriptionDictionary.types.START:
      state.dictionary = null;
      state.loadings.dictionary = true;
      state.errors = initialState.errors;
      return state;

    case getSubscriptionDictionary.types.SUCCESS: {
      const { data, plan_id, addons } = action.payload;

      if (!data.subscription) {
        state.dictionary = data;
        state.loadings.dictionary = false;
        return state;
      }

      const products = data.subscription.products;
      const selectedProduct = getProductByPlanId(products, plan_id) || products[0];
      const plans = selectedProduct.plans;
      const hasPlans = plans.length > 0;
      const selectedPlan = hasPlans
        ? getPlanById(plans, plan_id) || getPlan(selectedProduct)
        : null;
      const selectedAddons =
        selectedProduct && !!addons.length && getAddonsByIds(selectedProduct.addOns, addons);
      const totalPrice = getTotalPrice(selectedPlan, selectedAddons);

      state.dictionary = { ...data, selectedProduct, selectedPlan, selectedAddons, totalPrice };
      state.loadings.dictionary = false;
      return state;
    }

    case getSubscriptionDictionary.types.FAIL:
      state.dictionary = null;
      state.loadings.dictionary = false;
      return state;

    // UPDATE SUBSCRIPTION DICTIONARY
    case updateSubscriptionDictionary.types.INIT:
      state.dictionary = state.dictionary ? { ...state.dictionary, ...action.payload.data } : null;
      return state;

    // GET SUBSCRIPTION TRANSACTIONS
    case getSubscriptionTransactions.types.START:
      state.loadings.transactions = true;
      return state;

    case getSubscriptionTransactions.types.SUCCESS:
      state.transactions.data =
        action.payload.page === 1
          ? action.payload.data
          : state.transactions.data.concat(action.payload.data);
      state.transactions.meta.current_page = action.payload.meta.current_page;
      state.transactions.meta.last_page = action.payload.meta.last_page;
      state.transactions.meta.per_page = action.payload.meta.per_page;
      state.loadings.transactions = false;
      return state;

    case getSubscriptionTransactions.types.FAIL:
      state.loadings.transactions = false;
      return state;

    // CLEAR SUBSCRIPTION TRANSACTIONS
    case clearSubscriptionTransactions.types.INIT:
      state.transactions = initialState.transactions;
      return state;

    // ADD SUBSCRIPTION PAYMENT
    case addSubscriptionPayment.types.START:
      state.errors.payment = null;
      state.loadings.payment = true;
      return state;

    case addSubscriptionPayment.types.SUCCESS:
      if (state.dictionary) {
        state.dictionary.subscription_id = action.payload.subscription_id;
        state.dictionary.subscription_end_date = action.payload.subscription_end_date;
      }
      state.loadings.payment = false;
      return state;

    case addSubscriptionPayment.types.FAIL:
      state.errors.payment = action.payload.message;
      state.loadings.payment = false;
      return state;

    // ADD SUBSCRIPTION REGISTRATION
    case addSubscriptionRegistration.types.START:
      state.errors.registration = null;
      state.loadings.registration = true;
      return state;

    case addSubscriptionRegistration.types.SUCCESS:
      if (state.dictionary && action.payload) state.dictionary.app_account_temp = action.payload;
      state.errors.registration = null;
      state.loadings.registration = false;
      return state;

    case addSubscriptionRegistration.types.FAIL:
      state.errors.registration = action.payload.message;
      state.loadings.registration = false;
      return state;

    // REGISTER SUBSCRIPTION SERVICE
    case registerSubscriptionService.types.START:
      state.errors.registration = null;
      state.loadings.registration = true;
      return state;

    case registerSubscriptionService.types.SUCCESS:
      if (state?.subscriptionDetails?.subscription?.payload && action.payload) {
        const subscriptionPayload = state.subscriptionDetails.subscription.payload;

        if (Array.isArray(subscriptionPayload)) {
          state.subscriptionDetails.subscription.payload = subscriptionPayload.map(item => {
            if (item.app_id === action.payload.app_id) {
              return { ...item, ...action.payload };
            }

            return item;
          });
        } else {
          state.subscriptionDetails.subscription.payload = {
            ...state.subscriptionDetails.subscription.payload,
            ...action.payload,
          };
        }
      }
      state.errors.registration = null;
      state.loadings.registration = false;
      return state;

    case registerSubscriptionService.types.FAIL:
      state.errors.registration = action.payload.message;
      state.loadings.registration = false;
      return state;

    // CLEAR REGISTRATION ERRORS
    case clearRegisterSubscriptionErrors.types.INIT:
      state.errors.registration = null;
      return state;

    // CANCEL SUBSCRIPTION
    case cancelSubscription.types.START:
      state.loadings.cancel = true;
      return state;

    case cancelSubscription.types.SUCCESS:
      if (state.subscriptionDetails)
        state.subscriptionDetails.subscription = action.payload.subscription;
      state.loadings.cancel = false;
      return state;

    case cancelSubscription.types.FAIL:
      state.loadings.cancel = false;
      return state;

    // RENEW SUBSCRIPTION
    case renewSubscription.types.START:
      state.loadings.renew = true;
      return state;

    case renewSubscription.types.SUCCESS:
      if (state.subscriptionDetails)
        state.subscriptionDetails.subscription = action.payload.subscription;
      state.loadings.renew = false;
      return state;

    case renewSubscription.types.FAIL:
      state.loadings.renew = false;
      return state;

    // GET SUBSCRIPTION REVIEW
    case getSubscriptionPreview.types.START:
      state.loadings.preview = true;
      return state;

    case getSubscriptionPreview.types.SUCCESS:
      state.preview = action.payload;
      state.loadings.preview = false;
      return state;

    case getSubscriptionPreview.types.FAIL:
      state.loadings.preview = false;
      return state;

    // CHANGE SUBSCRIPTION PASSWORD
    case changePassword.types.START:
      state.loadings.changePassword = true;
      state.errors.changePassword = null;
      return state;

    case changePassword.types.SUCCESS:
      state.loadings.changePassword = false;
      return state;

    case changePassword.types.FAIL:
      state.loadings.changePassword = false;
      state.errors.changePassword = action.payload;
      return state;

    // CHANGE APP PRICE
    case userChangeAppPrice.types.SUCCESS:
      if (state.subscriptionDetails)
        state.subscriptionDetails.app.user_price = action.payload.data.price;
      return state;

    // UPDATE WATCHLISTS
    case addToWatchList.types.SUCCESS:
    case removeFromWatchList.types.SUCCESS: {
      const isAdd = action.type === addToWatchList.types.SUCCESS;
      const { content_id, watch_lists } = action.payload;
      const { subscriptionDetails } = state;

      if (!(subscriptionDetails?.popular_content?.length && content_id)) {
        return state;
      }

      state.subscriptionDetails.popular_content = state.subscriptionDetails.popular_content.map(
        item => {
          if (item.id === content_id) {
            const wlAction = isAdd ? addWatchlists : removeWatchlists;
            const watchlists = wlAction(item.watchlists, watch_lists);

            return { ...item, watchlists, in_watchlist: !!watchlists.length };
          }
          return item;
        },
      );

      return state;
    }

    // CHANGE CONTENT STATUS
    case changeContentStatus.types.SUCCESS: {
      const { content_id, status } = action.payload;

      if (!(state.subscriptionDetails?.popular_content?.length && content_id)) {
        return state;
      }

      state.subscriptionDetails.popular_content = state.subscriptionDetails.popular_content.map(
        item => updateUserContentStatus(item, content_id, status),
      );

      return state;
    }

    // MANAGE SUBSCRIPTION AUTH DIALOG STATE
    case manageSubscriptionAuthDialogState.types.INIT:
      state.authDialogIsOpen = action.payload.state;
      state.authDialogProps = action?.payload?.props || {};
      state.errors.registration = null;
      return state;

    default:
      return state;
  }
});

export default reducer;
