import { format as formatFn, intervalToDuration } from 'date-fns';
import StringMask from 'string-mask';

// UTILITY
import { convertToDate } from '@/utility/date';

const cloudfront = process.env.REACT_APP_CLOUDFRONT_LINK;

export const getDuration = m => {
  const duration = intervalToDuration({ start: 0, end: m * 60 * 1000 });

  return `${duration.hours}h ${duration.minutes}m`;
};

export const getContentYear = date => {
  return date ? formatFn(convertToDate(date), 'yyyy') : '';
};

export const getYearRange = (range, status, currentMessage) => {
  if (!range) return '';

  const startYear = range.start ? getContentYear(range.start) : '';
  const endYear = range.end ? getContentYear(range.end) : '';
  let yearRange = startYear;

  if (!endYear && status && (status === 'In Production' || status === 'Returning Series')) {
    yearRange += ` - ${currentMessage}`;
  } else if (endYear > startYear) {
    yearRange += ` - ${endYear}`;
  }

  return yearRange;
};

/**
 * @type{(params:{
 * title:string;
 * width?: number;
 * height?:number;
 * ext?:string;
 * color?:string;
 * bgColor:string
 * }) => string}
 */
export function getPlaceholderUrl({
  title = '',
  width = 165,
  height = 247,
  ext = 'jpg',
  color = '000000',
  bgColor = 'FFFFFF',
}) {
  const text = encodeURIComponent(title.replace(/['"]/g, ''));

  return `https://dummyimage.com/${width}x${height}.${ext}/${bgColor}/${color}?text=${text}`;
}

export function getS3ImageUrl(path) {
  return `${cloudfront}/assets/images${path}`;
}

export const updateUserContentStatus = (item, contentId, contentStatus) => {
  let newItem = { ...item };

  if (item.id === contentId) {
    newItem = {
      ...newItem,
      user_content_status: contentStatus,
    };
  }

  return newItem;
};

export const updateListStatus = (item, contentId, contentStatus) => {
  let newItem = { ...item };

  if (item.id === contentId) {
    newItem = {
      ...newItem,
      list_status: contentStatus,
    };
  }

  return newItem;
};

export const addWatchlists = (watchlists, needToAdd) => {
  const newWatchlists = needToAdd.map(wl => ({ id: wl }));
  return [...watchlists, ...newWatchlists];
};

export const removeWatchlists = (watchlists, needToRemove) => {
  return watchlists.filter(watchlist => !needToRemove.includes(watchlist.id));
};

// /** @type{(phone?:string|number, format?:'###-###-####') => string | NumberFormat} */
export function formatPhoneNumber(phoneNumber = '', format = '###-###-####') {
  if (!phoneNumber) {
    return '';
  }

  const phone = String(phoneNumber);

  return phone.length <= 10 ? StringMask.apply(phoneNumber, format) : phone;
}

export function getSubscriptionLink(routeLink, params) {
  const queryParams = new URLSearchParams(params).toString();

  return `${routeLink}?${queryParams}`;
}

/**
 *
 * @param {array} currentArray
 * @param {array} compareArray
 * @returns {array}
 */
export function shiftContent(currentArray, compareArray = [], contentType) {
  const uniqueItems = [];
  const shiftedItems = [];

  if (!(Array.isArray(currentArray) && Array.isArray(compareArray))) return currentArray;

  currentArray.forEach(currentItem => {
    const indexOf = compareArray.findIndex(compareItem => compareItem.id === currentItem.id);
    const shiftRangeEnd = contentType === 'app' ? 4 : 6;
    const shouldShift = indexOf !== -1 && indexOf < shiftRangeEnd;

    if (shouldShift) {
      shiftedItems.push(currentItem);
    } else {
      uniqueItems.push(currentItem);
    }
  });

  return uniqueItems.concat(shiftedItems);
}

/**
 *
 * @param {object} section
 * @returns {void}
 */
export function shiftSectionMutator(section) {
  let prevContent = null;

  for (const key in section) {
    if (Object.hasOwnProperty.call(section, key)) {
      if (Object.keys(section[key]).length) {
        if (prevContent) {
          section[key].data = shiftContent(
            section[key].data,
            prevContent.data,
            section[key].content_type,
          );
        }

        prevContent = section[key];
      }
    }
  }

  return section;
}

export function updateCarouselOptions(options) {
  let updatedResponsiveOptions = {};

  for (const media in options.breakpoints) {
    if (Object.hasOwnProperty.call(options.breakpoints, media)) {
      updatedResponsiveOptions = {
        ...options.breakpoints,
        [media]: {
          ...options.breakpoints[media],
          allowTouchMove: true,
        },
      };
    }
  }

  return {
    ...options,
    breakpoints: updatedResponsiveOptions,
  };
}

export function getGenresNames(genres = []) {
  return genres.map(genre => genre.genre_name).join(', ');
}

export function getPreparedResultsData(results) {
  return {
    apps: (results.apps && results.apps.map(app => app.id.toString())) || [],
    genres: (results.genres && results.genres.map(genre => genre.id.toString())) || [],
    content_types:
      (results.content_types && results.content_types.map(type => type.value.toString())) || [],
    runtime: results.runtime || '',
    title: results.title || '',
  };
}

export function getSeasonRangeDate(startDate, endDate) {
  if (!startDate) {
    return '';
  }

  const startYear = getContentYear(startDate);

  if (!endDate) {
    return startYear;
  }

  const endYear = getContentYear(endDate);

  return `${startYear}-${endYear}`;
}

export default getContentYear;
