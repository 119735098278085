import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useLocation, useParams } from 'react-router-dom';
import { AppBar, Hidden, Drawer, useMediaQuery, Button, Tooltip } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';

// CONSTANTS
import {
  ROUTE_START,
  ROUTE_RESULTS,
  ROUTE_STREAMING_REWARDS,
  ROUTE_PARTNER_STREAMING_REWARDS,
  ROUTE_STREAMING_CHOICE,
  ROUTE_PARTNER_STREAMING_CHOICE,
  ROUTE_ROOT,
} from '@/constants/routes';
import { LOCATIONS_HEADER, LOCATIONS_MOBILE_SIDE_BAR } from '@/constants/locations';
import { headerList } from '@/constants/menus';

// UTILITY
import { isHomeLocation } from '@/utility/routes';
import { getSearchParams } from '@/utility/common';

// STORE
import { AppActions, AuthActions, StreamingRewardsActions } from '@/store/actions';

// HOOKS
import useActions from '@/hooks/useActions';
import useRouterMatch from '@/hooks/useRouterMatch';

// COMPONENTS
import Logo from '@/components/Logo';
import Link from '@/components/Link';
import NavList from '@/components/NavList';
import BalanceTooltip from '@/components/StreamingRewards/BalanceTooltip';

// HELP
import DesktopMenu from './help/DesktopMenu';
import Search from './help/Search';

// STYLES
import {
  useStyles,
  HeaderWrapper,
  ToolbarCommon,
  ToolbarStyled,
  MenuButton,
  LoginLink,
  RoundButton,
  RightSection,
} from './styles';

const MyBundleHeader = props => {
  const { withoutShadow, innerRef } = props;
  const [t] = useTranslation();
  const { slug } = useParams();
  const location = useLocation();
  const isSRRoute =
    useRouterMatch({ path: ROUTE_STREAMING_REWARDS, end: false }) ||
    useRouterMatch({ path: ROUTE_PARTNER_STREAMING_REWARDS, end: false }) ||
    useRouterMatch({ path: ROUTE_STREAMING_CHOICE, end: false }) ||
    useRouterMatch({ path: ROUTE_PARTNER_STREAMING_CHOICE, end: false });
  const classes = useStyles();
  const matchesMd = useMediaQuery(({ breakpoints }) => breakpoints.up('md'));
  const matchesLg = useMediaQuery(({ breakpoints }) => breakpoints.up('lg'));

  const { orientation, unique_id, isAuth, authLoading } = useSelector(({ app, auth }) => ({
    orientation: app.orientation,
    unique_id: auth.unique_id,
    isAuth: auth.isAuth,
    authLoading: auth.loading,
  }));

  const [manageProfDrawerState, authDrawerOpen, checkStreamingRewards] = useActions([
    state => AppActions.manageProfDrawerState.action({ state }),
    (submitData = {}) => AuthActions.authDrawerOpen.action({ data: submitData }),
    StreamingRewardsActions.checkStreamingRewards.action,
  ]);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [menu, setMenu] = useState([]);
  const [showSearch, setShowSearch] = useState(false);

  const elevation = withoutShadow ? 0 : 5;

  useEffect(() => {
    setShowSearch(matchesLg);
  }, [matchesLg]);

  useEffect(() => {
    const newMenu = headerList.slice();

    newMenu.unshift({
      label: unique_id ? 'common.menu.results' : 'common.menu.start',
      route: unique_id
        ? {
            pathname: ROUTE_RESULTS,
            state: {
              fromHome: isHomeLocation(),
            },
          }
        : ROUTE_START,
    });

    if (!matchesMd) {
      newMenu.unshift({ label: 'common.menu.home', route: ROUTE_ROOT });
    }

    setMenu(newMenu);
  }, [matchesMd, unique_id]);

  if (mobileOpen && matchesMd) {
    setMobileOpen(false);
  }

  function handleDrawerToggle() {
    if (!matchesMd) {
      setMobileOpen(!mobileOpen);
    }
  }

  function checkEligibility(callbackData) {
    if (isSRRoute && !callbackData.userCard) {
      const authPromoCode = getSearchParams(location.search).get('auth');

      checkStreamingRewards({
        slug,
        mb_auth_code: authPromoCode,
        sr_slug: slug,
      });
    }
  }

  function handleLoginClick(e) {
    e.preventDefault();

    authDrawerOpen({ callback: isSRRoute && checkEligibility });
  }

  function handleRegistrationClick() {
    authDrawerOpen({ screen: 'registration', callback: isSRRoute && checkEligibility });
  }

  function handleOpenSearch() {
    setShowSearch(true);
  }

  function handleBlur() {
    if (!matchesLg) {
      setShowSearch(false);
    }
  }

  const mobileDrawer = (
    <Drawer
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      classes={{ paper: classes.drawerPaper }}
      className="header-menu-drawer"
      ModalProps={{
        keepMounted: true,
      }}
    >
      <NavList
        data={menu}
        handleMenuItem={handleDrawerToggle}
        location={LOCATIONS_MOBILE_SIDE_BAR}
      />

      <Hidden mdUp implementation="js">
        <div className="page-content drawer-buttons">
          {!isAuth && !authLoading && (
            <>
              <Button
                className="m-b-15"
                variant="outlined"
                size="medium"
                fullWidth
                onClick={handleRegistrationClick}
              >
                {t('buttons.registration')}
              </Button>
              <Button
                className="m-b-15"
                variant="outlined"
                size="medium"
                fullWidth
                onClick={handleLoginClick}
              >
                {t('buttons.login')}
              </Button>
            </>
          )}
          {!!unique_id && (
            <Button
              className="m-b-15"
              color="primary"
              size="medium"
              component={Link}
              to={{
                pathname: ROUTE_RESULTS,
                state: {
                  fromHome: isHomeLocation(),
                },
              }}
              fullWidth
              location={LOCATIONS_MOBILE_SIDE_BAR}
            >
              {t('buttons.backToResults')}
            </Button>
          )}
        </div>
      </Hidden>
    </Drawer>
  );

  const mobileMenu = (
    <Hidden mdUp implementation="css">
      <MenuButton
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className={clsx('header-burger', 'header-menu-drawer-button')}
      >
        <MenuIcon className={classes.iconSize} />
      </MenuButton>

      {/* Mobile Nav ------ */}
      {mobileDrawer}
    </Hidden>
  );

  const logo = <Logo location={LOCATIONS_HEADER} />;

  const desktopNav = (
    <>
      {/* Desktop Nav ------ */}
      <Hidden smDown implementation="js">
        <DesktopMenu data={menu} />
      </Hidden>
    </>
  );

  const search = <Search className="header__search" onBlur={handleBlur} autoFocus={!matchesLg} />;

  const searchSection =
    matchesLg && showSearch ? (
      <div className="header__search-container">{search}</div>
    ) : (
      <div className="header__search-buttons">
        <RoundButton
          className={clsx('round-button', 'round-button--search', matchesMd ? 'm-l-10' : 'm-l-5')}
          color="primary"
          variant="outlined"
          size="large"
          aria-label="open search"
          edge="start"
          onClick={handleOpenSearch}
        >
          <i className="icon-search" />
        </RoundButton>
      </div>
    );

  const authButtons = (
    <RightSection>
      {!isAuth && !authLoading && (
        <>
          <LoginLink component="span" onClick={handleLoginClick}>
            {t('buttons.login')}
          </LoginLink>
          {/* <div className="registration-btn">
            <span className="registration-btn__seperator">|</span>
            <LoginLink component="span" onClick={handleRegistrationClick}>
              {t('buttons.registration')}
            </LoginLink>
          </div> */}
        </>
      )}
    </RightSection>
  );

  const profileButton = (
    <div className="header__profile-buttons">
      <BalanceTooltip />
      <Tooltip title={t('common.menu.profile')}>
        <RoundButton
          className={clsx('round-button profile-button')}
          color="primary"
          variant="outlined"
          aria-label="open drawer"
          edge="start"
          onClick={() => manageProfDrawerState(true)}
        >
          <PersonIcon fontSize="large" />
        </RoundButton>
      </Tooltip>
    </div>
  );

  return (
    <HeaderWrapper className={`${classes.root} page-header`} ref={innerRef}>
      <AppBar
        className={clsx(
          'header-appbar',
          !(orientation !== 'landscape') && 'header-inactive',
          orientation === 'landscape' && 'header-appbar--landscape',
        )}
        elevation={elevation}
        classes={{ root: classes.appBar }}
        position={orientation === 'portrait' ? 'fixed' : 'static'}
      >
        <ToolbarStyled>
          {mobileMenu}
          {showSearch && !matchesLg && search}
          {!(showSearch && !matchesLg) && (
            <>
              {logo}
              {desktopNav}
              {searchSection}
              <Hidden smDown implementation="js">
                {authButtons}
              </Hidden>
              {isAuth && profileButton}
            </>
          )}
        </ToolbarStyled>
        <div id="p-header-bottom-content" />
      </AppBar>
      {orientation === 'portrait' && <ToolbarCommon />}
    </HeaderWrapper>
  );
};

MyBundleHeader.defaultProps = {
  withoutShadow: false,
};

MyBundleHeader.propTypes = {
  withoutShadow: PropTypes.bool,
  innerRef: PropTypes.any,
};

export default React.memo(MyBundleHeader);
