export const siteUrl = process.env.REACT_APP_SITE_URL || window.location.origin;
export const cloudfront = process.env.REACT_APP_CLOUDFRONT_LINK;
export const mobileAppDownloadLink = process.env.REACT_APP_MOBILE_APP_DOWNLOAD_LINK;
export const mobileAppDownloadLinkQR = process.env.REACT_APP_MOBILE_APP_DOWNLOAD_LINK_QR;

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const searchSections = [
  { type: 'app', title: 'common.apps', hash: 'app', ref: null },
  { type: 'channel', title: 'common.channels', hash: 'channel', ref: null },
  { type: 'show', title: 'common.tvShows', hash: 'show', ref: null },
  { type: 'movie', title: 'common.movies', hash: 'movie', ref: null },
  { type: 'content_crew', title: 'common.actorsDirectors', hash: 'crew', ref: null },
  // { type: 'insight', title: 'common.insights', hash: 'insight' },
];

export const pageSpeedBots = [
  'PingdomPageSpeed',
  'Google Page Speed Insights',
  'Chrome-Lighthouse',
];

export default searchSections;
