import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import {
  Grid,
  AppBar,
  Hidden,
  useMediaQuery,
  Button,
  Tooltip,
  Toolbar,
  IconButton,
  Link,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import clsx from 'clsx';

// CONSTANTS
import {
  ROUTE_START,
  ROUTE_RESULTS,
  ROUTE_DISCOVER,
  ROUTE_ROOT,
  ROUTE_SHOWS_AND_MOVIES,
  // ROUTE_LIVE_TV,
  ROUTE_STREAMING_REWARDS,
  ROUTE_PARTNER_STREAMING_REWARDS,
  ROUTE_STREAMING_CHOICE,
  ROUTE_PARTNER_STREAMING_CHOICE,
  ROUTE_FREE_TV,
  ROUTE_MOBILE_APP,
} from '@/constants/routes';
import { LOCATIONS_HEADER, LOCATIONS_MOBILE_SIDE_BAR } from '@/constants/locations';

// UTILITY
import { externalLink } from '@/utility/analytics';
import { isHomeLocation } from '@/utility/routes';
import { getSearchParams } from '@/utility/common';

// STORE
import { AppActions, AuthActions, StreamingRewardsActions } from '@/store/actions';

// HOOKS
import useActions from '@/hooks/useActions';
import useRouterMatch from '@/hooks/useRouterMatch';

// COMPONENTS
import Logo from '@/components/Logo';
import LogoPartner from '@/components/LogoPartner';
import CommonDrawer from '@/components/Drawers/CommonDrawer';
import NavList from '@/components/NavList';
import DesktopMenu from '@/components/Headers/MyBundleHeader/help/DesktopMenu';
import Search from '@/components/Headers/MyBundleHeader/help/Search';
import BalanceTooltip from '@/components/StreamingRewards/BalanceTooltip';

// STYLES
import { useStyles, Wrapper } from './styles';

const PartnerHeader = forwardRef((props, ref) => {
  const { withoutShadow, mainNavigation, backPartnerLink } = props;
  const [t] = useTranslation();
  const { isSimpleView, unique_id, isAuth, authLoading, partner, partnerSettings } = useSelector(
    ({ app, auth, partner: partnerStore }) => ({
      isSimpleView: app.isSimpleView,
      unique_id: auth.unique_id,
      isAuth: auth.isAuth,
      authLoading: auth.loading,
      partner: partnerStore.data,
      partnerSettings: partnerStore.data?.settings || {},
    }),
  );
  const [manageProfDrawerState, authDrawerOpen, checkStreamingRewards] = useActions([
    state => AppActions.manageProfDrawerState.action({ state }),
    (submitData = {}) => AuthActions.authDrawerOpen.action({ data: submitData }),
    StreamingRewardsActions.checkStreamingRewards.action,
  ]);
  const { slug } = useParams();
  const location = useLocation();
  const isSRRoute =
    useRouterMatch({ path: ROUTE_STREAMING_REWARDS, end: false }) ||
    useRouterMatch({ path: ROUTE_PARTNER_STREAMING_REWARDS, end: false }) ||
    useRouterMatch({ path: ROUTE_STREAMING_CHOICE, end: false }) ||
    useRouterMatch({ path: ROUTE_PARTNER_STREAMING_CHOICE, end: false });
  const classes = useStyles();
  const matchesMd = useMediaQuery(({ breakpoints }) => breakpoints.up('md'));
  const matchesLg = useMediaQuery(({ breakpoints }) => breakpoints.up('lg'));
  const orientation = useSelector(({ app }) => app.orientation);
  const isPortrait = orientation === 'portrait';

  const [mobileOpen, setMobileOpen] = useState(false);
  const [menu, setMenu] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [isShrunk, setShrunk] = useState(false);

  const subHeader =
    partnerSettings.header_type === 'current_with_bar' ||
    partnerSettings.header_type === 'current_with_bar_and_reordered_links';

  function handleExternalLink({ target }) {
    const properties = {
      location: LOCATIONS_HEADER,
      type: 'text',
      href: target.href,
      partner: partner.partner_slug,
      partner_id: partner.partner_id,
    };

    externalLink(properties);
  }

  useEffect(() => {
    let prevShrunk = false;

    const handleScroll = () => {
      const shrinkTop = 40;
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      const shrunk = scrollTop > shrinkTop;

      if (prevShrunk !== shrunk) {
        setShrunk(shrunk);

        prevShrunk = shrunk;
      }
    };

    if (subHeader) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, [subHeader]);

  useEffect(() => {
    setShowSearch(matchesLg);
  }, [matchesLg]);

  useEffect(() => {
    const newMenu = [];

    if (!matchesMd) {
      if (subHeader && partner.home_link) {
        newMenu.push({
          label:
            partner?.text_overwrites?.back_to_site_link ||
            t('buttons.backToHomePartner', { partner_name: partner.partner_name }),
          href: partner.home_link,
          component: 'a',
          target: '_blank',
          onClick: handleExternalLink,
          order: 0,
        });
      }

      newMenu.push({
        label: 'common.menu.home',
        route: ROUTE_ROOT,
        order: 0,
      });
    }

    if (partnerSettings.use_fmb) {
      newMenu.push({
        label: unique_id ? 'common.menu.results' : 'common.menu.start',
        route: unique_id
          ? {
              pathname: ROUTE_RESULTS,
              state: {
                fromHome: isHomeLocation(),
              },
            }
          : ROUTE_START,
        order: 3,
      });
    }

    if (partnerSettings.use_marketplace) {
      newMenu.push({
        label: 'common.menu.explore',
        route: ROUTE_DISCOVER,
        order: 2,
      });
    }

    if (partnerSettings.search_movies_shows && !isSimpleView) {
      newMenu.push({
        label: 'common.menu.tvShowsAndMovies',
        route: ROUTE_SHOWS_AND_MOVIES,
        order: 1,
      });
    }

    if (partnerSettings.show_live_tv_link && !isSimpleView) {
      newMenu.push({
        label: 'common.menu.freeTV',
        route: ROUTE_FREE_TV,
        order: 3,
      });
    }

    if (partnerSettings.show_mobile_link_in_nav && !isSimpleView) {
      newMenu.push({
        label: 'common.menu.mobileApp',
        route: ROUTE_MOBILE_APP,
        order: 4,
      });
    }

    if (partner.internet?.show) {
      newMenu.push({
        label: partner.internet?.button_text || 'common.menu.internet',
        href: partner.internet?.link,
        component: 'a',
        target: '_blank',
        onClick: handleExternalLink,
        order: 4,
      });
    }

    if (
      partnerSettings.header_type === 'current_with_reordered_links' ||
      partnerSettings.header_type === 'current_with_bar_and_reordered_links'
    ) {
      newMenu.sort((a, b) => a.order - b.order);
    }

    setMenu(newMenu);
  }, [matchesMd, partner, unique_id, window.location.pathname]);

  useEffect(() => {
    if (mobileOpen && matchesMd) {
      setMobileOpen(false);
    }
  }, [mobileOpen, matchesMd]);

  const handleDrawerToggle = () => {
    if (!matchesMd) {
      setMobileOpen(!mobileOpen);
    }
  };

  function checkEligibility(callbackData) {
    if (isSRRoute && !callbackData.userCard) {
      const authPromoCode = getSearchParams(location.search).get('auth');

      checkStreamingRewards({
        slug,
        mb_auth_code: authPromoCode,
        sr_slug: slug,
      });
    }
  }

  function handleLoginClick(e) {
    e.preventDefault();

    authDrawerOpen({ callback: isSRRoute && checkEligibility });
  }

  function handleRegistrationClick() {
    authDrawerOpen({ screen: 'registration', callback: isSRRoute && checkEligibility });
  }

  const handleOpenSearch = () => {
    setShowSearch(true);
  };

  const handleBlur = () => {
    if (!matchesLg) {
      setShowSearch(false);
    }
  };

  const search = <Search className="header__search" onBlur={handleBlur} autoFocus={!matchesLg} />;

  const searchSection =
    matchesLg && showSearch ? (
      <div className="header__search-container">{search}</div>
    ) : (
      <div className="header__search-button-container">
        <Button
          className={clsx('header__search-button', matchesMd ? 'm-l-10' : 'm-l-5')}
          color={matchesMd ? 'default' : 'primary'}
          variant="outlined"
          size="large"
          aria-label="open search"
          edge="start"
          onClick={handleOpenSearch}
        >
          <i className="icon-search" />
        </Button>
      </div>
    );

  return (
    <Wrapper className={clsx('header page-header', isShrunk && 'header--shrunk')} ref={ref}>
      <AppBar
        className={clsx('header__app-bar', !isPortrait && 'header__app-bar--landscape')}
        elevation={withoutShadow ? 0 : 5}
        position={isPortrait ? 'fixed' : 'static'}
      >
        {subHeader && (
          <Toolbar
            className={clsx(
              'header__toolbar-partner header__toolbar-partner--main',
              isShrunk && 'header__toolbar-partner--min',
            )}
          >
            <Grid
              container
              spacing={4}
              alignItems="center"
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Hidden xsDown implementation="js">
                <Grid item xs={4} className="backtohome">
                  {partner.home_link && backPartnerLink && (
                    <Link
                      color="inherit"
                      href={partner.home_link}
                      target="_blank"
                      onClick={handleExternalLink}
                    >
                      <i className="icon-arrow-left m-r-10" />
                      {partner?.text_overwrites?.back_to_site_link ||
                        (matchesMd
                          ? t('buttons.backToHomePartner', { partner_name: partner.partner_name })
                          : t('buttons.back'))}
                    </Link>
                  )}
                </Grid>
              </Hidden>
              <Grid item xs={12} sm={4}>
                <LogoPartner location={LOCATIONS_HEADER} />
              </Grid>
              <Hidden xsDown implementation="js">
                <Grid item xs={4} />
              </Hidden>
            </Grid>
          </Toolbar>
        )}
        {mainNavigation && (
          <Toolbar
            className={clsx(
              'header__toolbar',
              'header__toolbar--main',
              subHeader && 'header__toolbar--min',
            )}
          >
            <Hidden mdUp implementation="css">
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className="header__burger-button header-menu-drawer-button"
              >
                <MenuIcon fontSize="inherit" />
              </IconButton>
            </Hidden>
            {showSearch && !matchesLg && search}
            {!(showSearch && !matchesLg) && (
              <>
                {subHeader ? (
                  <Logo location={LOCATIONS_HEADER} />
                ) : (
                  <LogoPartner location={LOCATIONS_HEADER} />
                )}
                <Hidden smDown>
                  {/* Desktop Nav ------ */}
                  <DesktopMenu className="header__desktop-nav" data={menu} />
                </Hidden>
                <div className="header__right">
                  {subHeader && searchSection}
                  <Hidden smDown>
                    {!isAuth &&
                      !authLoading &&
                      Boolean(partnerSettings.use_authorizing) &&
                      !isSimpleView && (
                        <>
                          <div className="header__right-login">
                            <Link
                              className="header__auth-button"
                              component="span"
                              onClick={handleLoginClick}
                            >
                              {t('buttons.login')}
                            </Link>
                          </div>
                          <div className="header__right-signup">
                            <span className="m-r-15">|</span>
                            <Link
                              className="header__auth-button"
                              component="span"
                              onClick={handleRegistrationClick}
                            >
                              {t('buttons.registration')}
                            </Link>
                          </div>
                        </>
                      )}
                    {!subHeader && (
                      <Button
                        className="header__back-to-button"
                        color="primary"
                        size="large"
                        component="a"
                        href={partner.home_link}
                        target="_blank"
                      >
                        {partner?.text_overwrites?.back_to_site_link ||
                          `${t('common.backTo')} ${partner.partner_name}`}
                      </Button>
                    )}
                  </Hidden>
                  {isAuth && !!partnerSettings.use_authorizing && !isSimpleView && (
                    <div
                      className={clsx(
                        'header__profile-buttons',
                        subHeader && !matchesLg && 'm-l-5',
                      )}
                    >
                      <BalanceTooltip />
                      <Tooltip title={t('common.menu.profile')}>
                        <Button
                          color="primary"
                          variant="outlined"
                          aria-label="open drawer"
                          className="profile-button"
                          edge="start"
                          onClick={() => manageProfDrawerState(true)}
                        >
                          <PersonIcon fontSize="large" />
                        </Button>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </>
            )}
          </Toolbar>
        )}
        <div id="p-header-bottom-content" />
      </AppBar>
      {isPortrait && subHeader && (
        <Toolbar
          className={clsx(
            'header__toolbar-partner header__toolbar-partner--main',
            'header__toolbar-partner--fake',
            isShrunk && 'header__toolbar-partner--min',
          )}
        />
      )}
      {isPortrait && mainNavigation && (
        <Toolbar
          className={clsx(
            'header__toolbar',
            'header__toolbar--fake',
            subHeader && 'header__toolbar--min',
          )}
        />
      )}
      <CommonDrawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{ paper: classes.drawerPaper }}
        className="header-menu-drawer"
      >
        <NavList
          data={menu}
          handleMenuItem={handleDrawerToggle}
          location={LOCATIONS_MOBILE_SIDE_BAR}
        />
        <Hidden mdUp>
          <div className="drawer-buttons">
            {!isAuth && !authLoading && !!partnerSettings.use_authorizing && !isSimpleView && (
              <>
                <Button
                  className="m-b-15"
                  variant="outlined"
                  size="medium"
                  fullWidth
                  onClick={handleRegistrationClick}
                >
                  {t('buttons.registration')}
                </Button>
                <Button
                  className="m-b-15"
                  variant="outlined"
                  size="medium"
                  fullWidth
                  onClick={handleLoginClick}
                >
                  {t('buttons.login')}
                </Button>
              </>
            )}
          </div>
        </Hidden>
      </CommonDrawer>
    </Wrapper>
  );
});

PartnerHeader.defaultProps = {
  withoutShadow: false,
  mainNavigation: true,
  backPartnerLink: true,
};

PartnerHeader.propTypes = {
  withoutShadow: PropTypes.bool,
  innerRef: PropTypes.any,
  mainNavigation: PropTypes.bool,
  backPartnerLink: PropTypes.bool,
};

export default React.memo(PartnerHeader);
