import { combineReducers } from 'redux';

import app from './app';
import auth from './auth';
import user from './user';
import notifications from './notifications';
import pages from './pages';
import wizard from './wizard';
import counties from './counties';
import channel from './channel';
import channels from './channels';
import results from './results';
import discover from './discover';
import appDetails from './appDetails';
import wpBlog from './wpBlog';
import planDetails from './planDetails';
import movieDetails from './movieDetails';
import showDetails from './showDetails';
import content from './content';
import internetOption from './internetOption';
import locations from './locations';
import partner from './partner';
import internetOffers from './internetOffers';
import watchNext from './watchNext';
import watchList from './watchList';
import onboarding from './onboarding';
import subscription from './subscription';
import showsAndMovies from './showsAndMovies';
import swimlanes from './swimlanes';
import promotion from './promotion';
import search from './search';
import ipApi from './ipApi';
import streamingRewards from './streamingRewards';
import streamingRewardsFunds from './streamingRewardsFunds';
import share from './share';
import paymentHistory from './paymentHistory';
import virtualCardBalance from './virtualCardBalance';
import channelLineup from './channelLineup';
import slingBestPackage from './slingBestPackage';

export default combineReducers({
  app,
  auth,
  user,
  notifications,
  pages,
  wizard,
  counties,
  channel,
  channels,
  results,
  discover,
  appDetails,
  wpBlog,
  planDetails,
  movieDetails,
  showDetails,
  content,
  internetOption,
  locations,
  partner,
  internetOffers,
  watchNext,
  watchList,
  onboarding,
  subscription,
  showsAndMovies,
  swimlanes,
  promotion,
  search,
  ipApi,
  streamingRewards,
  streamingRewardsFunds,
  share,
  paymentHistory,
  virtualCardBalance,
  channelLineup,
  slingBestPackage,
});
